import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/wholehome-wifi-m.jpg';
import heroImageD from '../../images/wholehome-wifi-d.jpg';
import WHWifiPackages from './WHWifiPackages/WHWifiPackages';
import WHManagedWifi from './WHManagedWifi/WHManagedWifi';
import WHWifiFeatures from './WHWifiFeatures/WHWifiFeatures';
import { BASE_PAGE_TILE } from '../../lib/constants';

const WholeHomeWifiPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: WholeHome WiFi</title>
      </Helmet>
      <div className=''>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc='smiling family: daughter, mother, father, son'
          designElement='wild-circle'
        >
          <h1 className='headline__h1'>
            WiFi<br />Designed<br />for You
          </h1>
        </Hero>
        <WHWifiPackages />
        <WHManagedWifi />
        <WHWifiFeatures />
      </div>
      
    </>
  )
};

export default WholeHomeWifiPage;