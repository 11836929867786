import React, { ForwardedRef, forwardRef } from 'react';
import Button from '../Button/Button';
import styles from './OfferCard.module.scss';
import { Color } from '../../lib/types';
import { ROUTES } from '../../lib/constants';

export interface IOfferCardProps {
  headline: string;
  subhead: string;
  price: string;
  borderColor: Color;
  children: JSX.Element[];
  reveal?: boolean;
  onWhite?: boolean;
  bgColor?: string;
}

const OfferCard = forwardRef((props: IOfferCardProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {

  return (
    <div
      ref={ref}
      className={`${styles.container} border-color--${props.borderColor}`}
      style={props.bgColor ? { '--bg-color': props.bgColor } as React.CSSProperties : undefined }
      data-reveal={ props.reveal || false }
      data-on-white={props.onWhite || false}
    >
      <div>
        <h3 className='headline__h3' data-full-size>
          {props.headline}
        </h3>

        <h4 className={`headline__h4 ${styles.subhead}`} data-on-white={props.onWhite || false}>
          {props.subhead}
        </h4>

        {props.children[0]}
      </div>

      <div className='main-text'>
        <h3 className={`headline__h3 ${styles.price}`} data-full-size>
          {props.price}<span data-on-white={props.onWhite || false}>/mo</span>
        </h3>
        {props.children[1]}
        <Button
          href={ROUTES.checkAvailability.path}
          target='_self'
          size='md'
        >
          Select Package
        </Button>
      </div>
    </div>
  )
});

OfferCard.displayName = 'OfferCard';

export default OfferCard;