import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './LegalPages.module.scss'


const DMCAPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: DMCA Policy</title>
      </Helmet>
      <div className='grid-12'>
        <div className={styles.container}>
          <h1 className='headline__h1 color--dark-blue'>
            CITYSIDE FIBER DMCA POLICY 
          </h1>
          <section className={styles.content}>
            <p className='italic'>
              Last Updated: July 2023
            </p>
            <p>
                Cityside Networks, LLC dba Cityside Fiber (“Cityside Fiber”) is committed to complying with U.S. copyright and related laws. We reserve the right to terminate, in appropriate circumstances, the service provided to any customer or user in accordance with the Digital Millennium Copyright Act (“DMCA”) and other applicable laws and regulations. Cityside Fiber will terminate the subscriptions (internet access) of repeat copyright infringers. Our copyright compliance policies do not affect any other rights Cityside Fiber may have under law or contract. 
            </p>

            <h2><u>DMCA NOTIFICATION</u></h2>
            <p>
                If you are a copyright owner or an agent of a copyright owner and you believe that your rights under U.S. copyright law have been infringed, you may submit a notification pursuant to the DMCA. After receiving a compliant notification regarding infringing material residing on Cityside Fiber&apos;s network(s), we will, to the extent required by applicable law, remove or disable access to the material that is alleged to be infringing and take reasonable steps to promptly notify the subscriber that we have removed or disabled access to the material. 
            </p>
            <p>
                Copyright owners may use their own notification of claimed infringement form that satisfies the requirements of Section 512(c)(3) of the U.S. Copyright Act. Under the DMCA, anyone who knowingly makes misrepresentations regarding alleged copyright infringement may be liable to Cityside Fiber, the alleged infringer, and the alleged copyright owner for damages incurred as a result of the misrepresentation. 
            </p>
            <p>
                You may direct DMCA notifications to Cityside Fiber&apos;s Designated Agent using the following information:<br/>
                Designated Agent: Glenn Nieves<br/>
                Email: dmca@citysidefiber.com<br/>
                Address: 100 Spectrum Center Drive, Suite 500, Irvine, CA 92618<br/>
                Telephone number: 877-914-2489<br/>
            </p>

            <h2>COUNTER NOTIFICATION</h2>
            <p>
                If a user receives a DMCA notification of alleged infringement and believes in good faith that the allegedly infringing works have been removed or blocked by mistake or misidentification, then that user may send us a counter notification. When we receive a counter notification that satisfies the requirements of the DMCA, we will provide a copy of it to the person who sent the original infringement notification and will follow the DMCA&apos;s procedures with respect to received counter notifications. In all events, all users expressly agree that Cityside Fiber will not be a party to any disputes or lawsuits regarding alleged copyright infringement. Users may file counter notifications with Cityside Fiber&apos;s designated agent using the contact information shown above. All counter notifications must satisfy the requirements of Section 512(g)(3) of the U.S. Copyright Act. 
            </p>
            <p>
                <span className='bold'>NOTE</span>: The information on this page is provided to you for informational purposes only, and is not intended as legal advice. If you believe your rights under U.S. Copyright law have been infringed, you should consult an attorney. 
            </p>
          </section>
        </div>
      </div>
      
    </>
  )
};

export default DMCAPage;