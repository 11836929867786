import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import styles from './Reveal.module.scss';

interface IProps {
  children: ReactNode;
  id?: string;
  label: string;
  className?: string;
  reveal?: boolean;
  onClick?: (e: MouseEvent) => void;
}

const Reveal = ({ id, label, children, className, reveal, onClick }: IProps): JSX.Element => {
  const [show, setShow ] = useState(false);

  const onClickCB = (e: MouseEvent) => {
    if (reveal === undefined) {
      setShow(!show);
    }
    if (onClick) onClick(e);
  }

  useEffect(() => {
    if (reveal !== undefined) {
      setShow(reveal);
    }
  }, [reveal])

  return (
    <div className={`${styles.container} ${className ?? ''}`} data-show={show}>
      <button id={id} className={styles.btn} onClick={onClickCB}>
        { label }
      </button>
      <div className={styles.content} >
        <div className={styles.inner}>
          { children }
        </div>
      </div>
    </div>
  )
};

export default Reveal;