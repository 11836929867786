import React, { FocusEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import './NavMenu.scss';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../lib/constants';
import wholeHomeWifiImg from '../../images/nav-wholehome-wifi.jpg';
import streamingTVImg from '../../images/nav-streaming-tv.jpg';
import businessServicesImg from '../../images/nav-business-services.jpg';
import enterpriseServicesImg from '../../images/nav-enterprise-services.jpg';
import whyFiberImg from '../../images/nav-why-fiber.jpg';
import checkAvailabilityImg from '../../images/nav-check-availability.jpg';
import associationsImg from '../../images/nav-associations.jpg';
import aboutUsImg from '../../images/nav-about-us.jpg';
import multiFamImg from '../../images/nav-multifamily-owners.jpg';
import constructionImg from '../../images/nav-construction.jpg';
import joinUsImg from '../../images/nav-join-us.jpg';
import blogImg from '../../images/a-person-in-orange-county-reading-cityside-fiber-blog.jpg';
import SVGIcon from '../SVGIcon/SVGIcon';


const NavMenu = (props: { show: boolean, hideSubNav: number }): JSX.Element => {
  const refs = useRef<HTMLUListElement[]>([]);
  const [active, setActive] = useState<HTMLButtonElement>();
  const location = useLocation();
  const [prevPath, setPrevPath] = useState<string>();

  const onSetActiveEvent = (target: HTMLButtonElement) => {
    if (window.innerWidth >= 960) {
      setActive(target);
    }
  }

  const setNotActive = useCallback(() => {
    if (active) {
      active.dataset.active = 'false';
      (active.nextElementSibling as HTMLUListElement).dataset.show = 'false';
      setActive(undefined);
    }
  }, [active, setActive]);

  const onBtnMouseOver = (e: MouseEvent) => {
    onSetActiveEvent(e.target as HTMLButtonElement);
  }

  const onBtnFocus = (e: FocusEvent<HTMLButtonElement>) => {
    onSetActiveEvent(e.target as HTMLButtonElement);
  }

  const onMouseLeave = () => {
    if (active && window.innerWidth >= 960) {
      setNotActive();
    }
  }

  useEffect(() => {
    if (active) {
      refs.current.forEach(el => {
        el.dataset.show = 'false';
        (el.previousElementSibling as HTMLUListElement).dataset.active = 'false';
      });
      //
      active.dataset.active = 'true';
      (active.nextElementSibling as HTMLUListElement).dataset.show = 'true';
    }
  }, [active]);

  useEffect(() => {
    if (active && prevPath !== location.pathname) {
      setNotActive();
    }
    setPrevPath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (props.hideSubNav && active) {
      setNotActive();
    }
  }, [props.hideSubNav]);

  useEffect(() => {
    document.body.addEventListener('scroll', setNotActive);
  }, [setNotActive]);

  return (
    <div className='nav-wrap' data-show={props.show}>
      <nav>

        <button
          type='button'
          className='header__nav-link-header'
          onMouseOver={onBtnMouseOver}
          onFocus={onBtnFocus}
          data-active='false'
        >
          Residential Services
        </button>

        <ul
          className='sub-nav'
          data-show='false'
          onMouseLeave={onMouseLeave}
          ref={(el: HTMLUListElement) => {refs.current[0] = el}}
        >
          <li>
            <Link to={ROUTES.wholeHomeWifi.path}>
              <img src={wholeHomeWifiImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>WholeHome WiFi</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.streamingTV.path}>
              <img src={streamingTVImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Streaming TV</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.whyFiber.path}>
              <img src={whyFiberImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Why Fiber?</span>
            </Link>
          </li>

          <li>
            <a href={ROUTES.checkAvailability.path} target="_self">
              <img src={checkAvailabilityImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Check my Availability</span>
            </a>
          </li>
        </ul>

        <button
          type='button'
          className='header__nav-link-header'
          onMouseOver={onBtnMouseOver}
          onFocus={onBtnFocus}
          data-active='false'
        >
          Business Solutions
        </button>

        <ul
          className='sub-nav'
          data-show='false'
          onMouseLeave={onMouseLeave}
          ref={(el: HTMLUListElement) => {refs.current[1] = el}}
        >
          <li>
            <Link to={ROUTES.businessServices.path}>
              <img src={businessServicesImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Business Services</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.enterpriseServices.path}>
              <img src={enterpriseServicesImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Enterprise Services</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.checkAvailability.path}>
              <img src={checkAvailabilityImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Check my Availability</span>
            </Link>
          </li>
        </ul>

        <button
          type='button'
          className='header__nav-link-header'
          onMouseOver={onBtnMouseOver}
          onFocus={onBtnFocus}
          data-active='false'
        >
          Communities
        </button>

        <ul
          className='sub-nav'
          data-show='false'
          onMouseLeave={onMouseLeave}
          ref={(el: HTMLUListElement) => {refs.current[2] = el}}
        >
          <li>
            <Link to={ROUTES.multiFamilyOwners.path}>
              <img src={multiFamImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Multifamily Owners</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.associations.path}>
              <img src={associationsImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Associations</span>
            </Link>
          </li>
        </ul>

        <button
          type='button'
          className='header__nav-link-header'
          onMouseOver={onBtnMouseOver}
          onFocus={onBtnFocus}
          data-active='false'
        >
          Cityside
        </button>

        <ul
          className='sub-nav'
          data-show='false'
          onMouseLeave={onMouseLeave}
          ref={(el: HTMLUListElement) => {refs.current[3] = el}}
        >
          <li>
            <Link to={ROUTES.construction.path}>
              <img src={constructionImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Construction</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.aboutUs.path}>
              <img src={aboutUsImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>About Us</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.joinUs.path}>
              <img src={joinUsImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Join Us</span>
            </Link>
          </li>

          <li>
            <Link to={ROUTES.blog.path}>
              <img src={blogImg} width='100' height='100' loading='lazy' />
              <span className='header__nav-link'>Blog</span>
            </Link>
          </li>
        </ul>

        {/* <a
          className='sign-in-wrap'
          href='https://myslicefiber.camvio.cloud/login'
          target='_self'
        >
          <span className='header__nav-link header__nav-link--sign-in'>
            Sign In
          </span>

          <SVGIcon id='user' color='ui-grey-4' />
        </a> */}

      </nav>
    </div>
  )
};

export default NavMenu;