import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/enterprise-hero-m.jpg';
import heroImageD from '../../images/enterprise-hero-d.jpg';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './EnterpriseServicesPage.module.scss';
import Button from '../../components/Button/Button';
import imageM from '../../images/enterprise-img-m.jpg';
import imageD from '../../images/enterprise-img-d.jpg';


const EnterpriseServicesPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Enterprise Services</title>
      </Helmet>
      <div className='bg-color--dark-blue'>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc='network cables plugged into server'
          bgColor='bright-blue'
          objectPosition='left'
          designElement='blue-radient-lines'
        >
          <h1 className='headline__h1'>
            Connect<br />like never<br />before
          </h1>
        </Hero>
        <section className={`grid-12 ${styles.underHero}`}>
          <h2 className='headline__h2'>Enterprise Opportunities</h2>

          <div className={styles.content}>
            <p>
              Cityside&apos;s fiber network is designed to provide medium and large business with connectivity possibilities closer to the edge, 
              resulting in data savings, lower latencies, and new revenue streams.
            </p>
            <p>
              Inquire today with our dedicated enterprise sales team to learn how we can help your business take advantage of these connectivity opportunities.
            </p>
          </div>

          <div className={styles.btnWrap}>
            <Button
              href='mailto: enterprise@citysidenetworks.com'
              size='md-to-lg'
              className='margin-auto bg-color--bright-blue color--dark-blue btn--lg-extra-padding focus-contrast'

            >
              Contact us<br />enterprise@citysidenetworks.com
            </Button>
          </div>
          
          <div className={styles.imgWrap}>
            <picture>
              <source media="(min-width: 720px)" srcSet={imageD} />
              <img
                src={imageM}
                alt="person in high tech office filled with large computer monitors"
                title="person in high tech office filled with large computer monitors"
                aria-label="person in high tech office filled with large computer monitors"
                width="100"
                height="100"
                loading="lazy"
              />
            </picture>
          </div>
        </section>
      </div>
    </>
  )
};

export default EnterpriseServicesPage;