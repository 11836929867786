import React from 'react';
import styles from './WFTeleHistory.module.scss'
import phoneImg from '../../../images/wf-telephone.jpg';
import cableImg from '../../../images/wf-cable.jpg';
import fiberImg from '../../../images/wf-fiber.jpg';
import Button from '../../../components/Button/Button';
import { ROUTES } from '../../../lib/constants';

const WFTeleHistory = (): JSX.Element => {
  
  return (
    <section className={styles.container}>
      <div className='grid-12'>
        <div className={styles.content}>

          <h2 className='headline__h2 color--dark-blue'>
            A quick history of telecommunication
          </h2>

          <div className={styles.images}>
            <div className={styles.imgWrap}>
              <img
                src={phoneImg}
                alt="vintage telephone"
                title="vintage telephone"
                aria-label="vintage telephone"
                width="100"
                height="100"
                loading="lazy"
              />

              <div className={styles.imgText}>
                <h3 className='headline__h2 color--white' data-medium-desktop>
                  Telephone
                </h3>

                <p>
                  Built for voice calling on landlines
                </p>
              </div>
            </div>

            <div className={styles.imgWrap}>
              <img
                src={cableImg}
                alt="vintage television on stand"
                title="vintage television on stand"
                aria-label="vintage television on stand"
                width="100"
                height="100"
                loading="lazy"
              />

              <div className={styles.imgText}>
                <h3 className='headline__h2 color--white' data-medium-desktop>
                  Cable
                </h3>

                <p>
                  Built for TV signals on copper wiring
                </p>
              </div>
            </div>

            <div className={styles.imgWrap}>
              <img
                src={fiberImg}
                alt="wonam sitting at small, round table looking at laptop"
                title="wonam sitting at small, round table looking at laptop"
                aria-label="wonam sitting at small, round table looking at laptop"
                width="100"
                height="100"
                loading="lazy"
              />

              <div className={styles.imgText}>
                <h3 className='headline__h2 color--white' data-medium-desktop>
                  Fiber
                </h3>
                
                <p>
                  Built for the internet<br />Built for you
                </p>
              </div>

              <Button
                href={ROUTES.checkAvailability.path}
                target='_self'
                size='lg'
                fullWidth={true}
                className='bg-color--orange color--dark-blue'
              >
                Build your plan
              </Button>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
};

export default WFTeleHistory;