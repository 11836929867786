import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/construction-hero-m.jpg';
import heroImageD from '../../images/construction-hero-d.jpg';
import { BASE_PAGE_TILE } from '../../lib/constants';
import ConstructionPageFAQ from './ConstructionPageFAQ/ConstructionPageFAQ';
import ConstructionPageMap from './ConstructionPageMap/ConstructionPageMap';

const ConstructionPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Construction</title>
      </Helmet>
      <div className=''>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc='woman sitting at office table typing on laptop'
          designElement='wild-circle'
          bgColor='orange'
        >
          <h1 className='headline__h1'>
            Connect<br />to the<br />future
          </h1>
        </Hero>
        <ConstructionPageFAQ />
        {/* <ConstructionPageMap /> */}
      </div>
      
    </>
  )
};

export default ConstructionPage;