import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';

import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/associations-m.jpg';
import heroImageD from '../../images/associations-d.jpg';
import formImageM from '../../images/assc-form-m.jpg';
import formImageD from '../../images/assc-form-d.jpg';
import Button from '../../components/Button/Button';
import FormContainer5050 from '../../components/FormContainer5050/FormContainer5050';
import AsscWifi from './AsscWifi/AsscWifi';

import APIService from '../../lib/api.service';

const FormImageContent = (): JSX.Element => (
<>
    <h3 className='headline__h3 color--white' data-full-size>
    Smart Community solutions are available for Homeowner&apos;s Associations, school districts, 
    city and public facilities, and more!
    </h3>
</>
)

const AssociationsPage = (): JSX.Element => {
    const formRef = useRef<HTMLFormElement | null>(null);
    const [showAnim, setShowAnim] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [formData, setFormData] = useState<FormData>();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setShowAnim(true); // Set spinner

        let data = {};
        for (const pair of (formData as FormData).entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
            data[`${pair[0]}`] = `${pair[1]}`;
        }

        data["datestamp"] = new Date().toJSON().slice(0, 10); // "YYYY-MM-DD"
        data["address"] = `${data["street"]}, ${data["city"]}, ${data["state"]},`;
        data["UTYPE"] = "M";
        data["phone"] = data["phone"].replaceAll("-", "").trim() // Sanitize to "XXXXXXXXXX"

        data["vserviceloc"] = null; // Pulled from previous form
        data["caccount"] = null;
        data["campaign"] = null;
        data["servicelevel"] = null;
        data["ucount"] = null;
        data["serviceable"] = "No";

        const submit = await APIService.postContactInfo(JSON.stringify(data));
        setShowAnim(false);
        setShowForm(false);

        // if (submit.status === 201) {
        //     setShowSubmissionSuccess(true);
        // } else {
        //     setShowOutsideServiceArea(true);
        // }
    }

    const onChange = () => {
        if (formRef.current) {
            setFormData(new FormData(formRef.current));
        }
    }

    useEffect(() => {
        if (formRef.current) {
            setFormData(new FormData(formRef.current));
        }
    }, [formRef.current])

    return (
        <>
        <Helmet>
            <title>{BASE_PAGE_TILE}: Accociations</title>
        </Helmet>
        <div className='bg-color--faded-orange'>
            <Hero
                imageM={heroImageM}
                imageD={heroImageD}
                imageDesc='man wearing ball cap and sunglasses sitting outside with laptop'
                designElement='circle-of-dots'
            >
            <div>
                <h1 className='headline__h1' data-smaller>
                    Connect<br />Your<br />Community<br />
                </h1>
                <h4 className='headline__h4' data-desktop-large>
                    Wherever you are
                </h4>
            </div>
            </Hero>
            <AsscWifi />

            {showForm &&
            <FormContainer5050 
                imageD={formImageD} 
                imageM={formImageM}
                imageDesc={'bird\'s-eye view or residential neighborhood'}
                formPosition='right'
                designElement='blue-radient-lines'
                designElPosition='top'
                imageFullWidth={false}
                imageBGColor='faded-neon-blue'
                imageContent={<FormImageContent />}
                loadingAnimationText='Submitting interest...'
                showLoadingAnimation={showAnim}
            >
            <form id='form' ref={formRef} onSubmit={onSubmit} onChange={onChange}>
                <h3 className='headline__h3 color--dark-blue'>
                    Submit your community interest
                </h3>

                <div className='multi-column-container'>
                    <div className='input-container'>
                        <label htmlFor='firstname'>First Name</label>
                        <input 
                            id='firstname'
                            name='firstname'
                            type='text'
                            required
                        />
                    </div>

                    <div className='input-container'>
                        <label htmlFor='lastname'>Last Name</label>
                        <input 
                            id='lastname'
                            name='lastname'
                            type='text'
                            required
                        />
                    </div>

                    <div className='input-container'>
                        <label htmlFor='email'>Email</label>
                        <input 
                            id='email'
                            name='email'
                            type='email'
                            required 
                        />
                    </div>
                </div>

                <div className='input-container'>
                    <label htmlFor='street'>Street Address</label>
                    <input 
                        name='street'
                        id='street'
                        type='text' 
                        autoComplete='off' 
                        placeholder="Steet Address" 
                        required
                    />
                </div>

                <div className='multi-column-container'>
                    <div className='input-container'>
                        <label htmlFor='city'>City</label>
                        <input 
                            id='city'
                            name='city'
                            type='text'
                            required
                        />
                    </div>

                    <div className='select-container'>
                        <label htmlFor='state'>State</label>
                        <select id='state' name='state' defaultValue='CA' required>
                        <option value='CA'>
                            California
                        </option>
                        </select>
                    </div>
                </div>

                <div className='input-container' data-desktop-half>
                <label htmlFor='phone'>Phone</label>
                <input 
                    id='phone'
                    name='phone'
                    type='tel'
                    pattern='[0-9]{3}-?[0-9]{3}-?[0-9]{4}'
                    placeholder='XXX-XXX-XXXX'
                    required
                />
                </div>

                <div className='input-container' data-desktop-half>
                <label htmlFor='businessname'>Community Name</label>
                <input 
                    id='businessname'
                    name='businessname'
                    type='text'
                    required
                />
                </div>            

                <Button
                    type='submit'
                    className='btn--lg-extra-padding'
                    size='md-to-lg'
                    fullWidth={true}
                >
                Submit
                </Button>
            </form>
            </FormContainer5050>
            }

            {!showForm &&
            <FormContainer5050 
                imageD={formImageD} 
                imageM={formImageM}
                imageDesc={'bird\'s-eye view or residential neighborhood'}
                formPosition='right'
                designElement='blue-radient-lines'
                designElPosition='top'
                imageFullWidth={false}
                imageBGColor='faded-neon-blue'
                imageContent={<FormImageContent />}
                loadingAnimationText='Submitting interest...'
                showLoadingAnimation={showAnim}
            >
            <form>
                <h1 className='headline__h1' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>thanks for<br />your interest!</span>
                </h1>
                <p>
                    Our Concierge team will reach out with more information shortly...
                </p>
            </form>
            </FormContainer5050>
            }
        </div>
        </>
    )
};

export default AssociationsPage;