import React from 'react';
import styles from './WHWifiFeatures.module.scss';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import Button from '../../../components/Button/Button';
import { ROUTES } from '../../../lib/constants';

const WHWifiFeatures = (): JSX.Element => {
  
  return (
    <section className={`grid-12 ${styles.container}`}>
      <div className={styles.content}>

        <h2 className='headline__h2 color--dark-blue'>
          With your WholeHome WiFi package from Cityside, you&apos;ll get...
        </h2>

        <div className={styles.featureWrap}>

          <div className={`main-text ${styles.feature}`}>
            <div className={styles.iconWrap}>
              <SVGIcon id='phone' color='dark-blue' />
            </div>

            <h3 className='headline__h3 color--dark-blue' data-subtitle>
              MyCity WiFi App
            </h3>

            <p>A remote control from your mobile device to your home&apos;s fiber internet</p>
            <ul>
              <li>Device management</li>
              <li>Parental controls</li>
              <li>Security</li>
              <li>Network segmenting and prioritization</li>
            </ul>
          </div>

          <div className={`main-text ${styles.feature}`}>
            <div className={styles.iconWrap}>
              <SVGIcon id='user' color='orange' />
            </div>

            <h3 className='headline__h3 color--dark-blue' data-subtitle>
              MyConcierge
            </h3>

            <p>Dedicated Concierge Assistance to guide you through any questions</p>
            <ul>
              <li>White-glove service team</li>
              <li>Equipment and technical experts</li>
              <li>Smart home connectivity support</li>
            </ul>
          </div>

          <div className={`main-text ${styles.feature}`}>
            <div className={styles.iconWrap}>
              <SVGIcon id='checkmark' color='faded-yellow' />
            </div>

            <h3 className='headline__h3 color--dark-blue' data-subtitle>
              MyGoLive
            </h3>

            <p>A smooth sign-on experience to get you started</p>
            <ul>
              <li>Equipment included</li>
              <li>Interior & Exterior installation included</li>
              <li>Service guidance FAQ</li>
            </ul>
          </div>

        </div>

        <Button 
          href={ROUTES.checkAvailability.path}
          target='_self'
          size='lg'
          className='btn--lg-extra-padding margin-auto' 
          gradient={true} 
          fullWidth={true}
        >
          Check my availability 
          <SVGIcon id='map-pin' color='white' />
        </Button>

      </div>
    </section>
  )
};

export default WHWifiFeatures;