import React from 'react';
import styles from './Container5050.module.scss';

interface IProps {
  imageD: string;
  imageM: string;
  imageDesc?: string;
  waveMask?: boolean;
  children: JSX.Element[];
}

const Container5050 = (props: IProps): JSX.Element => {

  return (
    <section className={styles.container}>
      <div className={styles.wave} data-mask={props.waveMask || false}>
        <div className={styles.radientLines}></div>
      </div>

      <div className={styles.contentWrap}>
        <div className={styles.radientLines}></div>

        <div className={`grid-12 ${styles.content}`}>

          <div className={styles.imgWrap}>
            <picture>
              <source media="(min-width: 720px)" srcSet={props.imageD} />
              <img
                src={props.imageM}
                alt={props.imageDesc}
                title={props.imageDesc}
                aria-label={props.imageDesc}
                width="100"
                height="100"
                loading="lazy"
              />
            </picture>
          </div>

          <div className={styles.textContent}>
            { props.children }
          </div>

        </div>
      </div>
    </section>
  )
};

export default Container5050;