import React, { ReactElement, ReactNode } from 'react';
import styles from './FormContainer5050.module.scss';
import { Color } from '../../lib/types';

interface IProps {
  children: ReactElement<HTMLFormElement>;
  imageD: string;
  imageM: string;
  imageDesc?: string;
  imageFullWidth?: boolean;
  imageContent?: ReactNode;
  imageBGColor?: Color;
  showLoadingAnimation?: boolean;
  loadingAnimationText?: string;
  formPosition?: 'left' | 'right';
  designElement?: 'orange-radient-lines' | 'blue-radient-lines';
  designElPosition?: 'top' | 'bottom';
  loadImage?: 'eager' | 'lazy';
}

const FormContainer5050 = (props: IProps): JSX.Element => {
  
  return (
    <section className={styles.container} data-form-position={props.formPosition || 'left'}>

      <div 
        className={`${styles.imgWrap}${props.imageBGColor ? ` bg-color--${props.imageBGColor}` : ''}`} 
        data-design-el-position={props.designElPosition || 'bottom'}
        data-full-width={props.imageFullWidth !== undefined ? props.imageFullWidth : true}
      >
        <div 
          className={styles.imgWrapInner}
          data-full-width={props.imageFullWidth !== undefined ? props.imageFullWidth : true}
        >
          <picture>
            <source media="(min-width: 720px)" srcSet={props.imageD} />
            <img
              src={props.imageM}
              alt={props.imageDesc}
              title={props.imageDesc}
              aria-label={props.imageDesc}
              width="100"
              height="100"
              loading={props.loadImage || 'lazy'}
            />
          </picture>
          { !!props.imageContent && <div className={styles.imgContentWrap}>{props.imageContent}</div> }
        </div>
        <div className={styles.designElement} data-design-el={props.designElement || 'orange-radient-lines'}></div>
      </div>

      <div className='grid-12'>
        {
          !props.showLoadingAnimation && (
            <div className={styles.formWrap}>
              { props.children }
            </div>
          )
        }
        {
          props.showLoadingAnimation && (
            <div className={styles.animWrap}>
              <div className={styles.wildCircle}></div>
              {
                !!props.loadingAnimationText && (
                  <h3 className='headline__h3 color--dark-blue'>
                    { props.loadingAnimationText }
                  </h3>
                )
              }
            </div>
          )
        }
      </div>

    </section>
  )
};

export default FormContainer5050;