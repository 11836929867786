import React, { useEffect, useState } from 'react';
import svgstore from '../../images/store.svg';
import './SVGIcon.scss';
import { Color } from '../../lib/types';


const SVGIcon = (props: {id: string, color?: Color, rotate?: number}): JSX.Element => {
  const [ origin, setOrigin ] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setOrigin(window.location.origin);
    }
  }, [])
  
  return (
    <svg className={`svg-icon svg-icon--${props.id} ${props.color ? 'fill--' + props.color : ''}`}>
      <use 
        xlinkHref={`${origin}${svgstore}#${props.id}`} 
        transform={`rotate(${props.rotate || 0})`}
        transform-origin='center'
      ></use>
    </svg>
  )
};

export default SVGIcon;