import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/why-fiber-m.jpg';
import heroImageD from '../../images/why-fiber-d.jpg';
import { BASE_PAGE_TILE, CLEARCO_WIDGET_CODE } from '../../lib/constants';
import styles from './JoinUs.module.scss';


const JoinUsPage = (): JSX.Element => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://careers-content.clearcompany.com/js/v1/career-site.js?siteId=${CLEARCO_WIDGET_CODE}`;
    script.async = true;

    // Replace 'myDivId' with the actual ID of your target div
    const clearcoEmbedDiv = document.getElementById('clearcoEmbed');
    clearcoEmbedDiv!.appendChild(script);
  }, []);

  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Join Us</title>
      </Helmet>
      <div className=''>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc='woman sitting at office table typing on laptop'
          designElement='orange-radient-lines'
        >
          <h1 className={`headline__h1 ${styles.headeline}`}>
            We&apos;re just<br />getting<br />started
          </h1>
        </Hero>
        <div className={`grid-12 ${styles.caption}`}>
          <p>
            Come join our rapidly growing team in Orange County!
          </p>
        </div>
        <section className={styles.jobsSection}>
          <div className='grid-12'>
            <h2 className='headline__h2'>
              Open positions
            </h2>

            <div className={styles.container}>
              <div id="clearcoEmbed"></div>
            </div>
          </div>
          <div className={`grid-12 ${styles.caption}`}>
            <p>
              Questions? Contact us at <a href="mailto:hrgroup@citysidefiber.com">hrgroup@citysidefiber.com</a>
            </p>
          </div>
        </section>
      </div>

    </>
  )
};

export default JoinUsPage;