import React, { useEffect } from 'react';
import styles from './OfferCardsContainer.module.scss';
import OfferCard, {IOfferCardProps} from '../OfferCard/OfferCard';


interface IProps {
  children: React.ReactElement<typeof OfferCard>[];
}

const OfferCardsContainer = (props: IProps): JSX.Element => {
  let target: HTMLDivElement | undefined;

  const onMouseOver = (e: MouseEvent) => {
    if (props.children.find(el => (el as any).ref?.current == e.target)){
      target = e.target as HTMLDivElement;
      target.parentNode?.childNodes.forEach(el => (el as HTMLDivElement).dataset.reveal = 'false');
      target.dataset.reveal = 'true';
    } 
  }

  const onBtnFocus = (e: FocusEvent) => {
    if (props.children.find(el => (el as any).ref?.current == (e.target as HTMLAnchorElement).parentElement?.parentElement)){
      target = (e.target as HTMLAnchorElement).parentElement?.parentElement as HTMLDivElement;
      target.parentNode?.childNodes.forEach(el => (el as HTMLDivElement).dataset.reveal = 'false');
      target.dataset.reveal = 'true';
    } 
  }

  useEffect(() => {
    const targets: HTMLDivElement[] = [];
    props.children.forEach((el, i) => {
      if ((el as any).ref?.current) targets.push((el as any).ref.current);
      targets[i].addEventListener('mouseover', onMouseOver);
      (targets[i].querySelector('.btn') as HTMLAnchorElement).addEventListener('focus', onBtnFocus);
    });
    //
    return () => {
      targets.forEach(el => {
        el.removeEventListener('mouseover', onMouseOver);
        (el.querySelector('.btn') as HTMLAnchorElement).removeEventListener('focus', onBtnFocus);
      })
    }
  }, [props.children]);

  return (
    <div className={styles.container}>
      { props.children }
    </div>
  )
};

export default OfferCardsContainer;