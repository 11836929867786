import React, { ComponentPropsWithoutRef, FocusEvent, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

interface IProps {
  children: ReactNode;
  size: 'sm' | 'md' | 'lg' | 'sm-to-md' | 'md-to-lg';
  className?: string;
  href?: string;
  target?: '_self' | '_blank';
  type?: 'button' | 'submit';
  path?: string;
  onClick?: (e: MouseEvent) => void;
  onFocus?: (e: FocusEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  secondary?: boolean;
  gradient?: boolean;
  border?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  attr?: ComponentPropsWithoutRef<'a'>
}

const Button = (props: IProps = { size: 'lg', children: '' }): JSX.Element => {
  return (
    <>
    {
      (props.onClick || props.type) && 
      <button 
        type={props.type || 'button'}
        onClick={props.onClick}
        onFocus={props.onFocus}
        className={`btn ${props.size === 'sm-to-md' ? 'btn--sm' : ''} ${props.size === 'md-to-lg' ? 'btn--md' : ''} btn--${props.size} ${props.className || ''}`}
        data-secondary={props.secondary || false}
        data-gradient={props.gradient || false}
        data-border={props.border || false}
        data-full-width={props.fullWidth || false}
        disabled={props.disabled || false }
      >
        { props.children }
      </button>
    }
    {
      props.path && 
      <Link 
        to={props.path}
        onFocus={props.onFocus}
        className={`btn ${props.size === 'sm-to-md' ? 'btn--sm' : ''} ${props.size === 'md-to-lg' ? 'btn--md' : ''} btn--${props.size} ${props.className || ''}`}
        data-secondary={props.secondary || false}
        data-gradient={props.gradient || false}
        data-border={props.border || false}
        data-full-width={props.fullWidth || false}
        data-disabled={props.disabled || false }
        {...props.attr}
      >
        { props.children }
      </Link>
    }
    {
      props.href && 
      <a 
        href={props.href}
        target={props.target || '_self'}
        onFocus={props.onFocus}
        className={`btn ${props.size === 'sm-to-md' ? 'btn--sm' : ''} ${props.size === 'md-to-lg' ? 'btn--md' : ''} btn--${props.size} ${props.className || ''}`}
        data-secondary={props.secondary || false}
        data-gradient={props.gradient || false}
        data-border={props.border || false}
        data-full-width={props.fullWidth || false}
        data-disabled={props.disabled || false}
        {...props.attr}
      >
        { props.children }
      </a>
    }
    </>
  )
};

export default Button;