import React from 'react';
import { ROUTES } from '../../../lib/constants';

import styles from './LPHero.module.scss';
import imageM from '../../../images/home-hero-m.jpg';
import imageD from '../../../images/home-hero-d.jpg';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import Button from '../../../components/Button/Button';

const LPHero = (): JSX.Element => {
  
  return (
    <section className={styles.hero}>
      <div className={styles.imageWrap}>
        <picture>
          <source media="(min-width: 720px)" srcSet={imageD} />
          <img
            className={styles.heroImage}
            src={imageM}
            alt="father with children looking at tablet"
            title="father with children looking at tablet"
            aria-label="father with children looking at tablet"
            width="100"
            height="100"
            loading="eager"
          />
        </picture>

        <div className={styles.radientLines}></div>
        <div className={styles.wave}></div>
      </div>
      
      <div className={`grid-12 ${styles.content}`}>
        <div className={styles.innerContent}>
          <h1 className='headline__h1'><span className='color--orange-gradient'>love</span> <span className='color--bright-blue'>your wifi</span></h1>
          <p>
            Cityside Fiber is Orange County&apos;s premier choice for high-speed internet and WiFi connectivity. 
            We are putting you back in control of your internet experience with Multi-Gig speed options, concierge services, security, 
            and parental controls. All this with a more reliable connection for your devices throughout your home or business.
          </p>

          <Button 
            href={ROUTES.checkAvailability.path}
            target='_self'
            size='md-to-lg'
            gradient={true}
            fullWidth={true}
          >
            Check my availability
            <SVGIcon id='map-pin' />
          </Button>
        </div>
      </div>
    </section>
  )
};

export default LPHero;