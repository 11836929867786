import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/cityside-team.jpg';
import heroImageD from '../../images/cityside-team.jpg';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './PressPage.module.scss'

const PressPage = (): JSX.Element => {
  
    return (
        <>
        <Helmet>
            <title>{BASE_PAGE_TILE}: In the News</title>
        </Helmet>
        <div className=''>
            <Hero
            imageM={heroImageM}
            imageD={heroImageD}
            imageDesc='cityside team'
            designElement='wild-circle'
            >
            <h1 className='headline__h1'>
                we&apos;re famous!<br/>(well, sort of)
            </h1>
            </Hero>

            <div className={`grid-12 ${styles.container}`}>
                <div className={styles.inner}>
                    <div className='main-text'>
                        <center>
                        <h2 className='headline__h2 color--dark-blue'>
                            In the News
                        </h2>
                        </center>

                        <center>
                        <br/>
                        <p className='bold'>
                            Cityside Fiber Expands Orange County Network into Lake Forest and Mission Viejo
                        </p>
                        <p className='italic'>
                            Construction is underway in Lake Forest for the growing fiber provider, with plans to break ground in Mission Viejo this spring 
                        </p>
                        </center>
                        <br/>
                        <p>
                            <span className='bold'>IRVINE, Calif., March 19, 2024 /PRNewswire/</span> –  Cityside Networks, LLC (dba Cityside Fiber),
                            Cityside Fiber, a fiber-to-the-premise (&quot;FTTP&quot;) developer and internet service provider, 
                            has announced the expansion of its high-performance fiber network with the beginning of construction in Lake Forest. 
                            Cityside Fiber is also preparing to break ground in neighboring Mission Viejo this spring, 
                            furthering its commitment to providing high-speed internet connectivity to homes and businesses throughout Orange County.
                        </p>
                        <p>
                            Cityside Fiber completed the development of its first projects in Tustin and Dana Point in late 2023,
                            enabling upgraded connectivity for local residents. In addition to further build-outs throughout those cities,
                            Cityside&apos;s network construction has begun in Lake Forest, with customer activations slated for the second half of 2024.
                            Principal development work for Mission Viejo is also underway, with the expansion of fiber to additional Orange County cities to be announced later in 2024.
                        </p>
                        <p>
                            &quot;We are proud to lead the way for fiber expansion in Orange County,&quot; said Rod Hanson,
                            Cityside Fiber CEO and co-founder. &quot;Our investment in the communities where we work and live will drive next-generation
                            technology advancements that create social and economic opportunities and give our neighbors an upgraded customer experience
                            from a local provider who cares.&quot;
                        </p>
                        <p>
                            Unlike traditional cable and DSL, Cityside offers a 100% fiber-optic network with the fastest and most reliable technology.
                            With speed packages delivering up to 5 Gigabits per second, Cityside&apos;s network enables lightning-fast downloads,
                            seamless TV streaming and video, and lag-free online gaming. The network&apos;s infrastructure provides a stable connection
                            less susceptible to environmental interference and signal degradation. Cityside commits to delivering exceptional customer service,
                            including no long-term contracts and straightforward billing without hidden fees, surcharges, or surprise price increases.
                        </p>
                        <p>
                            Cityside Fiber is the ticket to the digital future for Orange County residents and businesses.
                            To learn more, please visit <a href="https://www.citysidefiber.com/">www.citysidefiber.com.</a>
                        </p>
                        <p className='bold'>
                            About Cityside Fiber
                        </p>
                        <p>
                            Founded in 2021 and headquartered in Orange County, California,
                            Cityside Fiber is a fiber-to-the-premise (&quot;FTTP&quot;) developer and internet service provider.
                            In addition to its residential offerings, Cityside provides wholesale fiber products to
                            business customers by deploying networks with capacity and flexibility to support future
                            smart city applications, 5G deployments, and other connectivity use cases in Southern California
                            and expansion markets.
                        </p>
                        <p>
                            For press inquiries, contact
                            <a href='mailto: sales@citysidefiber.com'>
                                <span className='bold'>&nbsp;jsa_cityside@jsa.net.</span>
                            </a>
                        </p>

                        <center>
                        <br/>
                        <p className='bold'>
                            Cityside Fiber Expands Network Footprint Into Dana Point
                        </p>
                        <p className='italic'>
                            The regional provider recently broke ground in Dana Point as part of its ongoing infrastructure deployment in Orange County
                        </p>
                        </center>
                        <br/>
                        <p>
                            <span className='bold'>Irvine, CA. –  July 25, 2023</span> –  Cityside Networks, LLC (dba Cityside Fiber),
                            a fiber-to-the-premise (&quot;FTTP&quot;) and service provider,
                            broke ground to begin deployment of a new fiber infrastructure network in Dana Point this week.
                            The development follows Tustin as the second in Cityside Fiber&apos;s planned efforts to bring more
                            high-speed connectivity options to businesses and residents in Orange County.
                        </p>
                        <p>
                            The newly added coverage area will benefit from Cityside Fiber&apos;s
                            robust all-fiber network and commitment to delivering reliable, affordable,
                            and incredibly fast internet services. With its expansion into Dana Point,
                            Cityside Fiber&apos;s solutions will provide access to advanced digital resources,
                            as residents and businesses can leverage multiple gigabit speeds for enhanced productivity,
                            seamless communication, and improved educational and entertainment opportunities. 
                        </p>
                            &quot;Quality of life is a huge reason why residents choose Dana Point.
                            This commitment by Cityside to invest in upgraded fiber infrastructure empowers our
                            community to thrive while attracting businesses and unlocking educational opportunities,
                            &quot; said Dana Point Mayor Mike Frost. &quot;It&apos;s not just about faster internet - it&apos;s
                            about building a stronger foundation for our city&apos;s success with a partner that is dedicated
                            to our community.&quot;
                        <p>
                            As a locally-operated company, Cityside Fiber provides an elevated experience
                            with hands-on, personalized customer service. Dana Point residents will soon have
                            access to Cityside Fiber&apos;s competitive internet plans starting at $80 per month,
                            with packages that provide up to 5 Gigs of speed.
                        </p>
                        <p>
                            &quot;This expansion into Dana Point reflects Cityside&apos;s dedication to connecting
                            communities and enhancing opportunity and quality of life for residents and businesses alike,
                            &quot; said Jonathan Restivo, Co-Founder & Chief Development Officer of Cityside Fiber.
                            &quot;We understand the critical role that reliable connectivity plays in today&apos;s world,
                            and our network is designed to support local businesses, facilitate remote work,
                            and provide the community with more options to function successfully in this modern digital environment.
                            We&apos;re thrilled to be able to bring this level of service to the people of Dana Point.&quot;
                        </p>
                        <p>
                            The Dana Point network is anticipated to be live later this summer.
                            Once operational, businesses and residents can expect reliable,
                            high-speed internet services that will empower them to thrive in today&apos;s digital world.
                            Individuals may check the availability of Cityside Fiber in their area and
                            find out more by visiting www.citysidefiber.com.
                        </p>
                        <p className='bold'>
                            About Cityside Fiber
                        </p>
                        <p>
                            Founded in 2021 and headquartered in Orange County, California,
                            Cityside Fiber is a fiber-to-the-premise (&quot;FTTP&quot;) developer and internet service provider.
                            In addition to its residential offerings, Cityside provides wholesale fiber products to
                            business customers by deploying networks with capacity and flexibility to support future
                            smart city applications, 5G deployments, and other connectivity use cases in Southern California
                            and expansion markets.
                        </p>
                        <p>
                            For press inquiries, contact
                            <a href='mailto: sales@citysidefiber.com'>
                                <span className='bold'>&nbsp;jsa_cityside@jsa.net.</span>
                            </a>
                        </p>

                        <br/>
                        <center>
                        <br/>
                        <p className='bold'>
                            Cityside Fiber Begins All-Fiber Network Construction in Orange County, California
                        </p>
                        <p className='italic'>
                            The new regional provider will bring multiple-gigabit internet service to Tustin residents
                        </p>
                        </center>
                        <br/>

                        <p>
                            <span className='bold'>Irvine, CA. –  May 31, 2023</span> –  Cityside Networks, LLC (dba Cityside Fiber),
                            a fiber-to-the-premise (&quot;FTTP&quot;) and service provider,
                            announces it has broken ground on constructing an all-fiber network in Tustin, California.
                            The fiber development, and future deployment, will offer Tustin residents and businesses fast,
                            reliable, secure internet access and WiFi services at multiple gigabit speeds.
                        </p>
                        <p>
                            Cityside Fiber takes a customer-first approach as a locally-based and operated company.
                            Its fiber network development will provide direct-to-subscriber cost-effective,
                            competitive internet plans with speeds up to 10 Gbps. The network will further enable customers&apos;
                            access to resources needed for remote learning, work-from-home, telehealth, and more.
                            Additionally, the company is carefully coordinating directly with the City of Tustin
                            to ensure a safe and effective rollout.
                        </p>
                        <p>
                            &quot;Increasing the fiber connectivity options for Tustin residents is one of many vital components
                            of the City&apos;s overall efforts to enhance the quality of life for Tustin residents,&quot;
                            said Tustin Councilmember Ryan Gallagher of the 4th District, where initial construction has begun.
                            &quot;Cityside Fiber&apos;s investment in our city will not only enable residents to work,
                            learn and connect with each other more efficiently, but provide the choice and access required
                            in today&apos;s digital environment.&quot;
                        </p>
                        <p>
                            &quot;We share a vision with our customers and understand the area&apos;s lack of choice when it comes
                            to affordable and dependable high-speed internet options,&quot; said Rod Hanson,
                            Founder & CEO of Cityside Fiber. &quot;With the development of our all-fiber network,
                            Tustin residents and businesses will be given the opportunity to thrive in today&apos;s ever-evolving
                            digital landscape with a high-quality service. Cityside Fiber is a local company that prioritizes
                            the customer experience and supports our community initiatives that make Orange County a better place
                            to live and work.&quot;
                        </p>
                        <p>
                            As part of Cityside Fiber&apos;s ongoing commitment to bring high-speed internet access to
                            Orange County, California communities, the company will have additional fiber network builds
                            in surrounding cities throughout 2023.
                        </p>
                        <p className='bold'>
                            About Cityside Fiber
                        </p>
                        <p>
                            Founded in 2021 and headquartered in Orange County, California,
                            Cityside Fiber is a fiber-to-the-premise (&quot;FTTP&quot;) developer and internet service provider.
                            In addition to its residential offerings, Cityside provides wholesale fiber products to
                            business customers by deploying networks with capacity and flexibility to support future
                            smart city applications, 5G deployments, and other connectivity use cases in Southern California
                            and expansion markets.
                        </p>
                        <p>
                            For press inquiries, contact
                            <a href='mailto: sales@citysidefiber.com'>
                                <span className='bold'>&nbsp;jsa_cityside@jsa.net.</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
};

export default PressPage;