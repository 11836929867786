import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './LegalPages.module.scss'


const BetaPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Beta Program</title>
      </Helmet>
      <div className='grid-12'>
        <div className={styles.container}>
          <h1 className='headline__h1 color--dark-blue'>
            Beta Program - Terms of Use 
          </h1>
          <section className={styles.content}>
            <p className='italic'>
                Updated on September 2023 
            </p>
            <p>
                PLEASE READ THESE BETA PROGRAM TERMS OF USE (THIS <span className='bold'>&quot;AGREEMENT&quot;</span>) CAREFULLY. THIS AGREEMENT IS BETWEEN YOU AND CITYSIDE NETWORKS, LLC DBA CITYSIDE FIBER, AND ITS AFFILIATES (<span className='bold'>&quot;CITYSIDE FIBER&quot;</span>) AND GOVERNS YOUR PARTICIPATION IN THE BETA PROGRAM (<span className='bold'>&quot;BETA PROGRAM&quot;</span>) AND YOUR ACCESS TO, EVALUATION, AND USE OF THE BETA PRODUCTS AND SERVICES MADE AVAILABLE TO YOU BY CITYSIDE FIBER AS PART OF THE BETA PROGRAM. BY ACCEPTING THESE TERMS AND CONDITIONS OR ACCESSING OR USING ANY BETA PRODUCTS OR SERVICES, YOU AGREE TO PARTICIPATE IN THE BETA PROGRAM AND BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT.
            </p>
            <p>
                YOU MAY ENTER INTO THIS AGREEMENT ON BEHALF OF YOURSELF OR ON BEHALF OF A LEGAL ENTITY. IF YOU ENTER INTO THIS AGREEMENT ON BEHALF OF A LEGAL ENTITY, YOU REPRESENT THAT YOU ARE A DULY AUTHORIZED REPRESENTATIVE WITH THE AUTHORITY TO BIND THAT LEGAL ENTITY TO THE TERMS AND CONDITIONS OF THIS AGREEMENT. ALL REFERENCES TO <span className='bold'>&quot;YOU&quot;</span> AND <span className='bold'>&quot;YOUR&quot;</span> IN THIS AGREEMENT MEAN THE PERSON ACCEPTING THESE TERMS AS AN INDIVIDUAL OR THE LEGAL ENTITY FOR WHICH THE REPRESENTATIVE IS ACTING, AS THE CASE MAY BE.
            </p>
            <p className='bold'>
                PLEASE BE ADVISED: THIS AGREEMENT INCLUDES AN EXCLUSION OF CERTAIN TYPES OF DAMAGES AND AN AGGREGATE LIMITATION ON LIABILITY THAT OPERATES AS A RELEASE AND WAIVER OF YOUR ABILITY TO RECOVER CERTAIN DAMAGES FROM CITYSIDE FIBER. THIS AGREEMENT ALSO INCLUDES A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER IN SECTION 11 (AGREEMENT TO ARBITRATE CLAIMS AND CLASS ACTION WAIVER) BELOW.
            </p>
            <ol>
              <li className='bold'>
                Beta Offering; Support 
              </li>
              <p>
                The beta offering includes any hardware, software, product, internet service, feature, functionality, or other technology that is either integrated with another offering or provided on a stand-alone basis by CITYSIDE FIBER and has been designated by CITYSIDE FIBER as part of its Beta Program (each, a <span className='bold'>“Beta Offering”</span>). CITYSIDE FIBER does not offer any service level agreement for a Beta Offering and CITYSIDE FIBER is not obligated to provide any maintenance or technical support. CITYSIDE FIBER reserves the right to modify, abandon, or impose any limitations on the use of any Beta Offering at any time, with or without notice, and without any obligation or liability. 
              </p>
              <li className='bold'>
                Eligibility; Participation
              </li>
              <p>
                Subject to CITYSIDE FIBER&apos;s prior approval, You may participate in the Beta Program upon request or by CITYSIDE FIBER&apos;s invitation. You must always meet the CITYSIDE FIBER-determined eligibility criteria during the Term (as defined in Section 5 (<span className='bold'>Term and Termination of Agreement; Effect of Termination</span>). If You cease to meet CITYSIDE FIBER&apos;s eligibility criteria, then CITYSIDE FIBER may immediately terminate Your participation in the Beta Program, Your access to any Beta Offering, or both. You shall provide any information required by CITYSIDE FIBER and, if required, create an account to use the Beta Offering. You shall follow all instructions from CITYSIDE FIBER in order to access, use, and create an account for the Beta Offering. You shall provide and maintain current, complete, and accurate information as requested by CITYSIDE FIBER. You shall maintain the confidentiality and security of any password that is created or maintained as part of using a Beta Offering. You shall notify CITYSIDE FIBER immediately of any unauthorized use of any password or account or of any suspected breach of the account&apos;s security.
              </p>
              <li className='bold'>
                Right to Use
              </li>
              <p>
                CITYSIDE FIBER grants You a right to access and use, during the Term, each Beta Offering provided by CITYSIDE FIBER to You. To the extent that access to or use of a Beta Offering requires or allows for You to download, use, or install software, CITYSIDE FIBER grants You a nonexclusive, nontransferable, nonassignable, nonsublicensable, royalty-free license to the software for Your internal use solely to access and use the Beta Offering during the Term. You acknowledge that Your rights under this Section 3 (<span className='bold'>Right to Use</span>) are revocable in CITYSIDE FIBER&apos;s sole discretion. 
              </p>
              <li className='bold'>
                Changes to Beta Offering; Suspension, Discontinuance, and Termination of Access and Use
              </li>
              <p>
                You acknowledge and agree that the form and nature of the Beta Offering, Beta Program, or both, may change from time to time without prior notice to You. Notwithstanding any provision set forth in this Agreement, CITYSIDE FIBER may terminate, limit, disconnect, deny access to, discontinue, abandon, modify, or suspend Your or any of Your end users&apos; use of a Beta Offering, participation in the Beta Program, or both, at any time, in CITYSIDE FIBER&apos;s sole discretion, with or without notice and without any obligation or liability to You, Your end users, or any third party. You acknowledge that Your or Your end user&apos;s breach of Section 6 (<span className='bold'>Acceptable Use; Prohibited Uses</span>), may result in immediate suspension, disconnection, or termination of access to the Beta Offering.
              </p>
              <li className='bold'>
                Term and Termination of Agreement; Effect of Termination
              </li>
              <p>
                The term (<span className='bold'>“Term”</span>) of this Agreement commences as of the date of You first access or use a Beta Offering and continues until terminated by a party as set forth below. CITYSIDE FIBER may terminate this Agreement at any time, with or without cause, upon delivery of written notice (email sufficient) to You. For the sake of clarity and not limitation, CITYSIDE FIBER may terminate this Agreement with cause immediately in the event of a violation of Section 6 (<span className='bold'>Acceptable Use; Prohibited Uses</span>) or any other material term of this Agreement by You or any of Your end users. You may terminate this Agreement at any time, with or without cause, by delivering at least thirty (30) days prior written notice to CITYSIDE FIBER. Upon any termination of this Agreement, all rights granted herein to use and access the Beta Offerings terminate and You and Your end users shall immediately: (i) cease use of all Beta Offerings and (ii) return or destroy all of CITYSIDE FIBER&apos;s Confidential Information (as defined in Section 8 (<span className='bold'>Confidential Information</span>)) and, if requested in writing by CITYSIDE FIBER, provide CITYSIDE FIBER with written certification of the same. 
              </p>
              <li className='bold'>
                Acceptable Use; Prohibited Uses
              </li>
              <p>
                a. You agree that use of the Beta Offerings by You and Your end users is subject to CITYSIDE FIBER&apos;s applicable policies located at https://www.citysidefiber.com/policies, including, without limitation, the Acceptable Use Policy, the Privacy Policy, and Terms of Service (as they pertain to installation of service, use of service, any responsibilities You may have)). You agree that You shall not, and shall not permit any of Your end users to: (i) make, use, or offer a Beta Offering for lease, rent, or sale, or reproduce, resell, distribute, lend, use on a timeshare or service bureau basis, or otherwise charge fees to other parties for use of the Beta Offering; (ii) knowingly or negligently use the Beta Offerings in a way that abuses, interferes with, or disrupts CITYSIDE FIBER&apos;s networks; (iii) engage in activity that is illegal, fraudulent, false, or misleading; or (iv) use the Beta Offerings in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law.
              </p>
              <li className='bold'>
                As-Is; Disclaimer of Warranties
              </li>
              <p>
                CITYSIDE FIBER EXPRESSLY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES, EITHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO ANY BETA OFFERING, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR THE CONTINUOUS, UNINTERRUPTED, ERROR-FREE, VIRUS-FREE, OR SECURE ACCESS TO OR OPERATION OF A BETA OFFERING. TO THE EXTENT CITYSIDE FIBER CANNOT DISCLAIM ANY SUCH WARRANTY AS A MATTER OF APPLICABLE LAW, THE SCOPE AND DURATION OF SUCH WARRANTY SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW. YOU ACKNOWLEDGE THAT THE BETA OFFERING IS BEING PROVIDED AND MADE AVAILABLE ON AN “AS IS” OR “AS AVAILABLE” BASIS WITHOUT ANY WARRANTY OF ANY KIND.
              </p>
              <p>
                YOU AGREE THAT THE BETA OFFERING: (A) IS NOT COMPLETE IN DEVELOPMENT AND HAS NOT BEEN COMMERCIALLY RELEASED FOR SALE BY CITYSIDE FIBER; (B) MAY NOT BE FULLY FUNCTIONAL AND MAY CONTAIN BUGS, ERRORS, DESIGN FLAWS, OR OTHER PROBLEMS; (C) MAY NOT BE RELIABLE; (D) WHEN USED, MAY RESULT IN UNEXPECTED RESULTS, LOSS OF CONTENT OR DATA, OR OTHER UNPREDICTABLE DAMAGE OR LOSS TO YOU. CITYSIDE FIBER EXPRESSLY DISCLAIMS ANY WARRANTY AS TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION, CONTENT, OR DATA ACCESSED OR USED IN CONNECTION WITH A BETA OFFERING. 
              </p>
              <li className='bold'>
                Confidential Information
              </li>
              <p>
                You and CITYSIDE FIBER agree to keep confidential all non-public, proprietary, or confidential information that You and CITYSIDE FIBER provide to each other in connection with the performance of this Agreement, the Beta Program, or any Beta Offering. You and CITYSIDE FIBER further agree to disclose Confidential Information (as defined in this Section 9 (<span className='bold'>Confidential Information</span>)) that You or CITYSIDE FIBER receive under this Agreement only to those employees, advisors, and attorneys of Yours or CITYSIDE FIBER&apos;s, as the case may be, who have a strict need to know the information being shared and who are bound by legal or contractual obligations to protect the confidentiality and security of the Confidential Information that are at least as restrictive as the obligations set forth in this Agreement. <span className='bold'>“Confidential Information”</span> includes, without limitation, business information, development plans, product roadmap details, systems, strategic plans, source code, services, products, pricing, methods, processes, financial data, programs, trade secrets, know-how, and marketing plans in any form that are designated as “confidential” or a reasonable person knows or reasonably should understand to be confidential.
              </p>
              <li className='bold'>
                Proprietary Rights
              </li>
              <p>
                You acknowledge and agree that CITYSIDE FIBER owns all legal right, title, and interest in and to the Beta Program and each Beta Offering, Feedback (as defined below in this Section 9 (<span className='bold'>Proprietary Rights</span>)), and any and all intellectual property (and other proprietary) rights therein or arising therefrom, including, but not limited to, any and all copyrights, patents, patent applications, trade secrets, trademarks, and other rights therein. Except for the license and other rights expressly granted to You in this Agreement, You acknowledge that nothing contained in this Agreement shall be deemed to give You, directly or by implication, estoppel, or otherwise, any rights, forbearances, or waivers under any intellectual property (or other proprietary) rights of CITYSIDE FIBER.
              </p>
              <p>
                Each Beta Offering is made available for purposes of evaluation and feedback in order to allow CITYSIDE FIBER to create improvements for the benefit of You and others, but without any financial compensation or reimbursement of any kind from CITYSIDE FIBER. You acknowledge the importance of communication with CITYSIDE FIBER while participating in the Beta Program and agree to receive correspondence and updates from CITYSIDE FIBER. You shall provide reasonable feedback and information, as requested. In the event You request to opt out from such communications, Your participation in the Beta Program, Your access to a Beta Offering, or both, may be canceled. During the Beta Program, You and Your end users will be asked to provide feedback and information regarding the use of the Beta Offering, and You acknowledge that CITYSIDE FIBER owns, and shall retain ownership of, all right, title, and interest in any suggestions, ideas, know-how, concepts, enhancements, feedback, recommendations or other information provided by You or any other party relating to a Beta Offering or the Beta Program, including without limitation, new features or functionality relating thereto (collectively, <span className='bold'>“Feedback”</span>). CITYSIDE FIBER or its licensors own and shall retain all proprietary rights, including all copyright, patent, trade secret, trademark, trade name, and all other intellectual property rights in and to each Beta Offering and the Feedback. You hereby assign to CITYSIDE FIBER all right, title, and interest in, and CITYSIDE FIBER is free to use without any attribution or compensation to You, any Feedback relating to a Beta Offering or underlying technology of a Beta Offering. All Feedback is and will be treated as confidential until CITYSIDE FIBER, in its sole discretion, chooses to make any specific Feedback non-confidential.
              </p>
              <li className='bold'>
                LIMITATION ON LIABILITY
              </li>
              <p className='bold'>
                <u>Please read this section carefully as it limits the liability of CITYSIDE FIBER to You.</u>
              </p>
              <p>
                IN NO EVENT WILL CITYSIDE FIBER OR ITS AFFILIATES BE LIABLE TO YOU OR ANY OTHER PARTY FOR DAMAGES OF ANY KIND ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER RESULTING FROM A TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, WARRANTY, OR OTHER FORM OF ACTION, AND INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, AND EVEN IF YOU HAVE ADVISED CITYSIDE FIBER OF THE POSSIBILITY OF SUCH DAMAGES. THE PARTIES AGREE THAT THE FOREGOING LIMITATION OF LIABILITY IS MATERIAL TO THIS AGREEMENT, AND THAT THESE LIMITATIONS APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. 
              </p>
              <li className='bold'>
                AGREEMENT TO ARBITRATE CLAIMS AND CLASS ACTION WAIVER
              </li>
              <p>
                Subject to the &quot;Exclusions&quot; paragraph below, Cityside Fiber and You agree to arbitrate disputes and claims arising out of or relating to the Beta Offering you have received from Cityside Fiber. Notwithstanding the foregoing, either party may bring an individual action on any matter or subject in small claims court. 
              </p>
              <p>
                THESE GENERAL TERMS MEMORIALIZE A TRANSACTION IN INTERSTATE COMMERCE.THE FEDERAL ARBITRATION ACT GOVERNS THE INTERPRETATION AND ENFORCEMENT OF THESE ARBITRATION PROVISIONS. 
              </p>
              <p>
                A party who intends to seek arbitration must first send to the other a written notice of intent to arbitrate, entitled &quot;Notice of Intent to Arbitrate&quot; (&quot;Notice&quot;). The Notice to Cityside Fiber should be addressed to: Legal Department, Cityside Networks, LLC dba Cityside Fiber, 100 Spectrum Center Drive, Suite 500, Irvine, CA 92618 (&quot;Arbitration Notice Address&quot;). The Notice must: (1) describe the nature and basis of the claim or dispute; and (ii) set forth the specific relief sought. If we do not reach an agreement to resolve the claim within 30 days after the Notice is received, You or Cityside Fiber may commence an arbitration proceeding, in which all issues are for the arbitrator to decide (including the scope of the arbitration clause), but the arbitrator shall be bound by the terms of this Agreement. 
              </p>
              <p>
                The arbitration shall be governed by the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (collectively, &quot;AAA Rules&quot;) of the American Arbitration Association (&quot;AAA&quot;), as modified by this Agreement and the arbitration shall be administered by the AAA. The AAA Rules and fee information are available at &quot;www.adr.org,&quot; by calling the AAA at 1-800-778-7879, or by writing to the Arbitration Notice Address. 
              </p>
              <p>
                CITYSIDE FIBER SHALL BEAR THE COST OF ANY ARBITRATION FILING FEES AND ARBITRATOR&apos;S FEES FOR CLAIMS OF UP TO $75,000. YOU RESPONSIBLE FOR ALL OTHER ADDITIONAL COSTS THAT YOU INCUR IN THE ARBITRATION INCLUDING, BUT NOT LIMITED TO, ATTORNEYS FEES OR EXPERT WITNESS COSTS UNLESS OTHERWISE REQUIRED OF CITYSIDE FIBER UNDER APPLICABLE LAW. 
              </p>
              <p>
                If the arbitrator&apos;s award exceeds $75,000, either party may appeal such award to a three-arbitrator panel administered by the AAA and selected according to the AAA Rules, by filing a written notice of appeal within 30 days after the date of entry of the arbitration award. The appealing party must provide the other party with a copy of such appeal concurrently with its submission of the appeals notice to AAA. The three-arbitrator panel must issue its decision within 120 days of the date of the appealing party&apos;s notice of appeal. The decision of the three-arbitrator panel shall be final and binding, except for any appellate right which may exist under the Federal Arbitration Act. 
              </p>
              <p>
                The parties may agree that arbitration will be conducted solely on the basis of the documents submitted to the arbitrator, via a telephonic hearing, or by an in-person hearing as established by AAA rules. 
              </p>
              <p>
                YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND CITYSIDE FIBER ARE WAIVING THE RIGHT TO A TRIAL BY JUDGE OR JURY. 
              </p>
              <p>
                Unless Cityside Fiber and you agree otherwise in writing, all hearings conducted as part of the arbitration shall take place in the county of your billing address. The arbitrator may award injunctive relief only in favor of the party seeking relief, only to the extent sought, and only to the extent necessary to provide the specific relief warranted by such individual&apos;s claim. The parties agree that the arbitrator must give effect to the terms of this Agreement. 
              </p>
              <p>
                YOU AND CITYSIDE FIBER AGREE THAT CLAIMS MAY ONLY BE BROUGHT IN YOUR INDIVIDUAL CAPACITY AND NOT ON BEHALF OF, OR AS PART OF, A CLASS ACTION OR REPRESENTATIVE PROCEEDING. 
              </p>
              <p>
                Furthermore, unless both You and Cityside Fiber agree otherwise in writing, the arbitrator may not consolidate proceedings or more than one person&apos;s claims and may not otherwise preside over any form of representative or class proceeding. If this specific paragraph is found to be unenforceable, then the entirety of these arbitration provisions shall be null and void and rendered of no further effect with respect to the specific claim at issue. 
              </p>
              <p>
                Right to Opt Out. If You do not wish to be bound by these arbitration provisions, You must notify Cityside Fiber in writing within 30 days of the date You first use the Beta Offering. You may opt out by mail to the Arbitration Notice Address. Your written notification to Cityside Fiber must include your name, address, and Cityside Fiber account number as well as a clear statement that You do not wish to resolve disputes with Cityside Fiber through arbitration.
              </p>
              <li className='bold'>
                Miscellaneous Terms
              </li>
              <p>
                a. <u>Notice</u> CITYSIDE FIBER may give notice by (i) electronic mail to Your e-mail address on record in Your account information, (ii) written communication sent by letter delivered by a nationally recognized overnight delivery service, or (iii) first-class postage prepaid mail to Your address on record in Your account information. You may give notice to CITYSIDE FIBER (such notice shall be deemed given when received by CITYSIDE FIBER) by (x) letter delivered by a nationally recognized overnight delivery service or first-class postage prepaid mail to CITYSIDE FIBER at <span className='bold'>100 Spectrum Center Drive, Suite 500, addressed to the attention of: Legal or (y) email to legal@citysidefiber.com, addressed to the attention of: Legal.</span>
              </p>
              <p>
                b. <u>No Agency Relationship</u> The parties do not intend for this Agreement to create an express or implicit agency relationship in accordance with federal or state common law of agency. Each party is intended to be an independent contractor and no agency relationship is created under this Agreement.
              </p>
              <p>
                c. <u>No Third-Party Rights or Remedies</u> This Agreement does not and is not intended to confer any enforceable rights or remedies upon any person other than CITYSIDE FIBER and You. 
              </p>
              <p>
                d. <u>Assignment; Successors and Assigns</u> You may not assign Your rights or transfer any of Your obligations under this Agreement without the express written consent of CITYSIDE FIBER and such consent may be granted or withheld in CITYSIDE FIBER&apos;s sole discretion. CITYSIDE FIBER may assign its rights or transfer any or all of its obligations under this Agreement at any time, without prior notice to You. Any purported assignment or transfer in violation of this section is null and void. This Agreement is binding upon, and inures to the benefit of, the parties and their respective permitted successors and assigns.
              </p>
              <p>
                e. <u>Changes to this Agreement; Additional Terms</u> CITYSIDE FIBER reserves the right to modify the terms and conditions of this Agreement from time to time, and CITYSIDE FIBER will use commercially reasonable efforts to communicate any material change to You. Use of the Beta Offering may require acceptance of new, modified, or additional terms of use from time-to-time by and through You, Your administrator, or other account owner. By continuing to use the Beta Offering or participate in the Beta Program and not issuing a notice of termination of this Agreement, unless expressly prohibited by law, You agree that such actions will be deemed acceptance of, and Your agreement to be bound by, any such new, modified, or additional terms.
              </p>
              <p>
                g. <u>Severability</u> If any provision of this Agreement is determined to be invalid, illegal, or unenforceable, the parties do not intend for this determination to affect or impair the validity, legality, and enforceability of the remaining provisions of this Agreement in any way. 
              </p>
              <p>
                h. <u>Survival</u> All sections of this Agreement which, by their nature should survive termination will survive termination, including, without limitation, sections pertaining to confidential information, any payment obligations, warranty disclaimers, indemnification, arbitration, and the limitation on liability. 
              </p>
              <p>
                i. <u>Governing Law</u> The laws of the State of California, U.S.A., regardless of conflict of laws principles, govern all matters arising out of or relating to this Agreement, except that the Federal Arbitration Act governs provisions relating to arbitration.
              </p>
              <p>
                j. <u>Ambiguity and Headings</u> The parties intend that any ambiguity in this Agreement will be resolved and interpreted as closely as possible to meet the intent of the parties. The captions and headings appearing in this Agreement are for reference only and are not intended to be considered in construing this Agreement.
              </p>
            </ol>
          </section>
        </div>
      </div>
      
    </>
  )
};

export default BetaPage;