import React, { MouseEvent, useState } from 'react';
import styles from './ConstructionPageFAQ.module.scss'
import Reveal from '../../../components/Reveal/Reveal';
import poster from '../../../images/construction-video-poster.jpg';
import Video from '../../../components/Video/Video';
import Button from '../../../components/Button/Button';
import video from '../../../video/construction.mp4';

const ConstructionPageFAQ = (): JSX.Element => {
  const [curID, setCurID] = useState<string>()

  const onClick = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement;
    if (curID !== target.id) {
      setCurID(target.id);
    } else {
      setCurID(undefined);
    }
  }

  return (
    <section className={styles.container}>
      <div className='grid-12'>
        <div className={styles.header}>
          <h2 className='headline__h2 color--dark-blue'>
            FAQ
          </h2>
          <h3 className='color--med-blue'>
            Questions about Construction?
          </h3>
        </div>

        <div className={styles.content}>
          <div>
            <Reveal id='1' onClick={onClick} reveal={curID === '1'} label='Who is Cityside?' className={styles.faq}>
              <p>
                Cityside Fiber is Orange County&apos;s premier choice for high-speed internet and WiFi connectivity.
                We are putting our subscribers back in control of your internet experience with faster speed options,
                concierge services, security and parental controls, and a more reliable connection throughout your home or business.
              </p>
            </Reveal>

            <Reveal id='2' onClick={onClick} reveal={curID === '2'} label='Are you building fiber on behalf of another company?' className={styles.faq}>
              <p>
                No, Cityside is in a unique position to be both the developer and the provider of internet service to you the subscriber.
                Cityside Fiber is a new company that set out to bring a better choice and better service to our neighbors in Orange County.
                When you are ready to sign up for service at www.citysidefiber.com, we would love to connect your home.
              </p>
            </Reveal>

            <Reveal id='3' onClick={onClick} reveal={curID === '3'} label='What does the construction process entail and what is the timeline within my neighborhood?' className={styles.faq}>
              <p>
                As we get ready to begin construction within a community, we communicate with residents to alert them of the project and any potential impacts,
                and make ourselves available to answer any questions. Construction will begin by first locating existing utilities in the community.
                Then, we will install conduit and fiber optic cabling with the least disruptive means possible for that street.
                Once the fiber is installed and the network is connected to the internet, we are able to bring fiber to homes and businesses as
                subscribers sign up for service and schedule their installation. The timeline for each community is different and depends on the
                size and construction techniques available, but on average we can pass 10-20 homes per day per crew.
              </p>
            </Reveal>

            <Reveal id='4' onClick={onClick} reveal={curID === '4'} label='Do you get permission from the city to install the Cityside Fiber network before you begin?' className={styles.faq}>
              <p>
                Yes! We have been working diligently with city officials to install our network in coordination with the City’s planned street work,
                and construction will take place only after all permits are obtained.
              </p>
            </Reveal>

            <Reveal id='5' onClick={onClick} reveal={curID === '5'} label='Where else is Cityside building fiber?' className={styles.faq}>
              <p>
                When we say we&apos;re a local company focused on delivering fiber access to our neighbors throughout Orange County,
                we really mean it. We have designed a network specifically to bring fiber to as many residents and businesses as we can get to within the community.
                It&apos;s a large project with a lot of moving parts all at once, but we are committed to the challenge. This also means our progress and timing is often changing.
                To see the most up to date details of where we are at with different neighborhoods and cities, and where we are going next, please see the map below
              </p>
            </Reveal>

            <Reveal id='6' onClick={onClick} reveal={curID === '6'} label='How do you connect my home? Do I need to signup for service?' className={styles.faq}>
              <p>
                Once your neighborhood is “live” on the network, our concierge service team will confirm a day and time for our technicians to first install an exterior connection from the curb to your home,
                then install your customized router and WiFi system within your home. So, a two-part process. For the exterior installation, it is not necessary for the owner to be present,
                but we would be happy to have you walk us through any concerns or notes. We take special care to avoid disrupting existing landscaping, paving, etc. as much as possible,
                and will address any potential issues directly with the homeowner prior to installation.
              </p>
              <p>
                Switching to Cityside Fiber for internet service is entirely up to the resident, though we would be thrilled to be able to connect your home.
                Just let us know if you have any questions on the signup or installation process.
              </p>
            </Reveal>

            <Reveal id='7' onClick={onClick} reveal={curID === '7'} label='How is fiber-based internet access different from what we currently have in the neighborhood?' className={styles.faq}>
              <p>
                Fiber optic cabling, unlike the cabling of yesteryear, is specifically built for a superior internet experience. With electronics sending signals
                through tiny fiber optic wires, enormous amounts of data are able to be sent simultaneously almost at the speed of light. This allows for both
                downloading and uploading information at multi-gigabit speeds, enabling clear video calls, bufferless movie streaming, gaming with less lag,
                large file transfers, and households with multiple internet users.
              </p>
              <p>
                Cityside Fiber plans start at 1 Gig symmetrical, which means in ideal conditions you can transfer up to 1000 megabits per second.
                Where this makes a difference is especially when uploading, or sending data back across the internet. Think video calls without faces freezing
                or interactive gaming at peak performance to outplay the competition. Cityside’s fiber internet plans include options for up to 5 Gigs of service
                speed!
              </p>
            </Reveal>

            <Reveal id='8' onClick={onClick} reveal={curID === '8'} label='What does WholeHome WiFi mean? What’s the difference between fiber internet and WiFi? Do I need to use your equipment?' className={styles.faq}>
              <p>
                At its most basic level, what Cityside provides to a subscriber is a blazing fast internet connection, powered by our fiber-based network.
                This gives you the subscriber access to a reliable and upgraded high-speed internet experience.
              </p>
              <p>
                What WiFi, and more particularly WholeHome WiFi does, is extend that connection wirelessly throughout your home (and yard, ask us how!).
                We are able to customize a WiFi setup in your home or business that maximizes service throughout the space. If additional equipment extenders
                are requested for a more powerful experience in a section of the home, (say for instance a corner home office), we have equipment available to
                easily add to your customized package.
              </p>
              <p>
                While we highly recommend utilizing our WiFi6 router equipment for optimal experience, we understand that you may have a system you purchased
                in the past that you would like to continue to use. Our installers and concierge team will do our best to link your system to our network to
                maximum effect.
              </p>
            </Reveal>
          </div>
          <div>
            <Video
              src={video}
              poster={poster}
            />

            <div className={styles.inquiries}>
              <p>Construction inquiries?</p>
              <Button
                className='bg-color--orange color--dark-blue'
                size='sm'
                href='mailto: construction@citysidefiber.com'
              >
                Construction@citysidefiber.com
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default ConstructionPageFAQ;