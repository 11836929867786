import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './LegalPages.module.scss'


const WebsiteTermsPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Website Terms of Use</title>
      </Helmet>
      <div className='grid-12'>
        <div className={styles.container}>
          <h1 className='headline__h1 color--dark-blue'>
            Website Terms of Use
          </h1>
          <section className={styles.content}>
            <p className='italic'>
              Last Updated: July 2023
            </p>
            <p>
                Welcome to the Cityside Fiber website and other Websites operated or controlled by Cityside Networks, LLC dba Cityside Fiber (&quot;Cityside&quot;) (collectively the &quot;Site(s)&quot;) which include content, text, information, advertising, data, audio/visual materials, &quot;applets,&quot; and software (collectively, &quot;Content&quot;), as well as communication tools, access online directories, administrative services, download areas, discussion forums, search, account management tools, access to certain content (e.g., community forums, support pages, news, or other supported content), certain diagnostic tools and other information (&quot;Services&quot;). The Services may themselves contain Content, and Content and Services are sometimes collectively referred to as the &quot;Resources&quot; (&quot;Site&quot;). By accessing this Site, you agree to be bound by the Terms and Conditions below. If you do not wish to be bound by these Terms of Use (&quot;Agreement&quot;), you may not access or use the Site. We may update this Agreement from time to time without notice. You have the obligation to review changes to this Agreement. Unless otherwise specified, such changes shall be effective when they are posted and will not be retroactive. Your access or use of this Site constitutes your agreement to be bound by this Agreement, including any changes that exist when you re-access our Site. 
            </p>

            <h2><u>Restrictions on Use of Site content</u></h2>
            <p>
                You may use, copy and distribute the materials found on this Site for personal, noncommercial, informational purposes only. All copies that you make of the material must bear any copyright, trademark or other proprietary notice located on the site that pertain to the material being copied. This Site contains materials supplied by Cityside and its affiliates, parents, and subsidiaries, as well as other sources, and is protected by copyrights, trademarks, servicemarks, patents, trade secrets, or other proprietary rights and laws. Except as expressly authorized by Cityside, you may not modify, copy, reproduce, republish, upload, post, transmit, distribute, sell, license, rent, publicly display or perform, edit, adapt, or create a derivative work of, in any manner, any material, content, or design elements obtained from this site, including code and software (&quot;Material&quot;).  
            </p>
            <p>
                You represent and warrant that they are the owner or otherwise have the right to provide any information or materials (including pre-existing materials) that they post or transmit for use in public or private areas on the Site. You hereby grant Cityside, its parents, affiliates, and subsidiaries an irrevocable, royalty-free, perpetual, non-exclusive worldwide license to use, copy, display, modify, edit, and create derivative works from and distribute any information or materials that you post or transmit in areas on our Site. You also agree to indemnify and hold Cityside, its parents, affiliates, and subsidiaries harmless from any claim or demand, including reasonable attorney&apos;s fees, made by any party arising out of information or materials that you submit on our Site. 
            </p>

            <h2><u>Choice of law and forum</u></h2>
            <p>
                Citysidefiber.com is originated and located in the United States, and this Agreement shall be governed by and construed in accordance with the laws of the State of California, excluding its conflicts of law rules. Any dispute arising out of or relating to this Agreement or your access or use of this Site will be subject to the exclusive jurisdiction of the courts located within the state of California, and you hereby submit to the personal jurisdiction of such courts. If any provision in this Agreement is held invalid or unenforceable, that provision shall be construed in a manner consistent with applicable law to reflect the original intent of the provision, and the remaining provisions of this Agreement shall remain in full force and effect. 
            </p>

            <h2><u>Access to interactive areas</u></h2>
            <p>
                This Site includes interactive areas for Users and may include chat service or areas where Users may submit data within Resources.  Any submissions or data submitted by you using the chat service, interactive areas, Resources and/or Site shall not be deemed confidential. We will not be responsible for any information or materials shared by you using Resources subject to any applicable law. 
            </p>

            <h2><u>Ordering Products and Services</u></h2>
            <p>
                You may be able to order certain Cityside products and services through this Site and Resources. All orders you make through this Site and Resources are subject to the availability, terms, and other conditions that apply to the particular products and services at the time you place your order. All products and services, their contents, availability, and pricing are subject to change at any time with or without notice. Please fully read the terms and disclaimers accompanying any products or services that you order through this Site and Resources. 
            </p>

            <h2><u>Disclaimer and Limitation of Liability </u></h2>
            <p>
                CITYSIDE FIBER AND ITS LICENSORS PROVIDE THE SITES AND RESOURCES &quot;AS IS&quot;, &quot;AS AVAILABLE&quot; AND &quot;WITH ALL FAULTS&quot; AND THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE SITES IS WITH YOU. SHOULD THE SITES OR ANY RESOURCE BE DEFECTIVE, YOU, AND NOT CITYSIDE FIBER OR ITS LICENSORS, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING AND REPAIR. CITYSIDE FIBER AND ITS LICENSORS EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, WITH RESPECT TO THE SITES AND RESOURCES (INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS). WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, CITYSIDE FIBER AND ITS LICENSORS MAKE NO WARRANTY THAT THE SITES OR RESOURCES WILL MEET YOUR REQUIREMENTS OR THAT THE SITES OR RESOURCES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE OR THAT DEFECTS IN THE SITES WILL BE CORRECTED, OR THAT ANY CONTENT OR OTHER MATERIAL ACCESSIBLE FROM THE SITES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NO ADVICE OR INFORMATION GIVEN BY CITYSIDE FIBER, ITS AFFILIATES, LICENSORS OR THEIR RESPECTIVE EMPLOYEES SHALL CREATE ANY WARRANTY. CITYSIDE FIBER AND ITS LICENSORS MAKE NO WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITES OR RESOURCES OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE SITES OR RESOURCES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE SITES OR RESOURCES OR FROM CITYSIDE FIBER OR ITS SUPPLIERS OR LICENSORS (OR THE RESPECTIVE OFFICERS, DIRECTORS, AFFILIATES, EMPLOYEES, OR AGENTS OF ANY SUCH ENTITIES) (COLLECTIVELY, &quot;THE CITYSIDE FIBER PARTIES&quot;) SHALL CREATE ANY WARRANTY. CITYSIDE FIBER DISCLAIMS ALL EQUITABLE INDEMNITIES. 
            </p>
            <p>
                IN NO EVENT WILL ANY OF THE CITYSIDE FIBER PARTIES BE LIABLE FOR (A) ANY INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR (B) ANY DAMAGES WHATSOEVER IN EXCESS OF ONE HUNDRED UNITED STATES (US$100.00) DOLLARS (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST REVENUES, LOSS OF GOODWILL, LOSS OF USE OR OTHER INTANGIBLE LOSSES), ARISING OUT OF OR IN CONNECTION WITH THE SITES (INCLUDING, WITHOUT LIMITATION, USE, INABILITY TO USE, OR THE RESULTS OF USE OF THE SITES OR RESOURCES), WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT, STATUTE, OR ANY OTHER LEGAL THEORY AND EVEN IF ANY CITYSIDE FIBER PARTY HAS BEEN ADVISED (OR SHOULD HAVE KNOWN) OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>

            <h2><u>Indemnity</u></h2>
            <p>
                You agree to indemnify, defend and hold harmless Cityside Fiber Parties from and against all losses, expenses, damages and costs, including reasonable attorneys&apos; and experts&apos; fees, arising from or related to claims made by any third-party due to or arising out of (a) Submitted Material or any other content you (or any parties who use your computer, with or without your permission) submit, post or upload to or transmit through the Sites or Resources, (b) your use of the Sites or Resources (or use of the Sites or Resources by any parties who use your computer, with or without your permission), (c) your violation of these Terms and/or your violation of any laws or regulations or the rights of another through the use of the Sites or Resources (or such violations by any parties who use your computer, with or without your permission), or (d) your interaction with Licensors or third parties or reliance on any advice provided by them. These indemnity obligations will survive termination of your relationship with Cityside Fiber or your ceasing to use the Sites or Resources. Cityside Fiber reserves the right to assume the defense and control of any matter subject to indemnification by you, in which event you will cooperate with Cityside Fiber in asserting any available defenses. 
            </p>
          </section>
        </div>
      </div>
      
    </>
  )
};

export default WebsiteTermsPage;