import React from 'react';
import styles from './StreamingTVCustomize.module.scss';
import Button from '../../../components/Button/Button';
import imageM from '../../../images/streaming-tv-customize-m.jpg';
import imageD from '../../../images/streaming-tv-customize-d.jpg';

const StreamingTVCustomize = (): JSX.Element => {
  
  return (
    <section>
      <div className={`grid-12 ${styles.container}`}>

        <h2 className='headline__h2 color--dark-blue'>
          Streaming Online Movies,<br />TV Channels, & Music is the best!
        </h2>

        <div className={styles.imgWrap}>
          <picture>
            <source media="(min-width: 720px)" srcSet={imageD} />
            <img
              src={imageM}
              alt="Person looking at streamin app on phone"
              title="Person looking at streamin app on phone"
              aria-label="Person looking at streamin app on phone"
              width="100"
              height="100"
              loading="lazy"
            />
          </picture>
        </div>

        <div className={`main-text ${styles.text}`}>
          <ul>
            <li>WiFi-Enabled backyard TVs & speaker systems</li>
            <li>Smart TVs & streaming service apps</li>
            <li>Stream music apps across your network</li>
            <li>No need to drill or open walls in new rooms</li>
            <li>Just plug in and enter the router information</li>
          </ul>

          <Button 
            href='http://streaming.citysidefiber.com/start'
            target='_self'
            size='md-to-lg'
            className='btn--lg-extra-padding bg-color--bright-blue color--dark-blue'
          >
            Customize your TV package
          </Button>
        </div>

      </div>
    </section>
  )
};

export default StreamingTVCustomize;