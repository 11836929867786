import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './LegalPages.module.scss'


const AUPPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Acceptable Use Policy</title>
      </Helmet>
      <div className='grid-12'>
        <div className={styles.container}>
          <h1 className='headline__h1 color--dark-blue'>
            Acceptable Use Policy
          </h1>
          <section className={styles.content}>
            <p className='italic'>
              Last Updated: July 2023
            </p>

            <p>
              This Acceptable Use Policy (&quot;AUP&quot;) applies to internet service (&quot;Service&quot;) subscribers (each, a &quot;Customer&quot; or &quot;You&quot;) of Cityside Networks, LLC. (dba Cityside Fiber) (&quot;Cityside Fiber&quot;). For purposes of this AUP, the Service includes all equipment and features which may be offered with the Service, such as speed tiers, data usage plans, and Wifi access. The policy is designed to protect the security, integrity, reliability, and privacy of both the Cityside Fiber network and the products and Services Cityside Fiber offers to Customer. Customer agrees to comply with this AUP and to remain responsible for its users.  Cityside Fiber reserves the right to update or modify this AUP at any time and from time to time with or without prior notice. Continued use of services provided by Cityside Fiber will be deemed acknowledgment and acceptance of the then current AUP. This AUP applies along with the Terms of Service governing the Customer&apos;s use of Service, and Cityside Fiber&apos;s Privacy Policy, to specify use restrictions and requirements applicable to users of the Service.
            </p>
            <h2>Prohibited Activities</h2>
            <p>
              Cityside Fiber prohibits use of its products and Services or any associated equipment in any manner that is unlawful, harmful to or interferes with its network or systems, or the network of any internet service provider (“ISP”), infringes or offensive material, a security risk or a violation of property. Such unacceptable use includes, but is not limited to, any of the following:
            </p>
            <ul>
              <li>
                Posting, transmission, re-transmission, or storing material on or through Cityside Fiber&apos;s Services, if in the sole judgment of Cityside Fiber such posting, transmission, re-transmission or storage is: (a) in violation of any local, state, federal, or non-United States law or regulation (including rights protected by copyright, trade secret, patent or other intellectual property or similar laws or regulations); (b) threatening, harassing or abusive; (c) obscene; (d) indecent; or (e) defamatory, fraudulent, libelous, excessively violent or promoting the use of violence or otherwise harmful to others. Customer shall be responsible for determining what laws or regulations are applicable to its use of the Services.
              </li>
              <li>
                Resale of Cityside Fiber&apos;s products and Services without the express prior written consent of Cityside Fiber.
              </li>
              <li>
                Deceptive marketing practices by impersonating others or deceptively obtaining personal information of third parties.
              </li>
              <li>
                Actions that restrict or inhibit anyone – whether a customer of Cityside Fiber or otherwise – in his or her use or enjoyment of Cityside Fiber&apos;s products and Services, or that generate excessive network traffic through the use of automated or manual routines that are not related to ordinary personal or business use of Service.
              </li>
              <li>
                Knowingly uploading or distributing files that contain malicious programs into the Cityside Fiber network or servers or other products and Service of Cityside Fiber such as viruses, trojan horses, worms that contain time bombs, cancel bots, spyware or any other similar software that may damage the operation of another&apos;s computer, network system or be used to engage in modem hi-jacking.
              </li>
              <li>
                Causing or attempting to cause security breaches or disruptions of internet communications.
              </li>
              <li>
                Executing any form of network monitoring aimed at unauthorized interception of data or harvesting of email addresses.
              </li>
              <li>
                Circumventing user authentication or security of any host, network or account. Services may not be used to interfere with, gain unauthorized access to, or otherwise violate the security of Cityside Fiber&apos;s (or another party&apos;s) server, network, network access, personal computer or control devices, software or data, or other system, or to attempt to do any of the foregoing.
              </li>
              <li>
                Interfering with or denying Service to any user other than the Customer&apos;s host (e.g., denial of service attack).
              </li>
              <li>
                Using any program/script/command, or sending messages of any kind, designed to interfere with, or to disable a user&apos;s terminal session.
              </li>
              <li>
                Furnishing false or incorrect data on the order form contract (electronic or paper) including fraudulent use of credit card numbers or attempting to circumvent or alter the processes or procedures to measure time, bandwidth utilization or other methods to document “use” of Cityside Fiber&apos;s Service
              </li>
              <li>
                Sending unsolicited mail messages, including the sending of &quot;junk mail&quot; or other material to individuals who did not specifically request such material, who were not previous customers of the customer or with whom the customer does not have an existing business relationship (e.g., E-mail “spam”); or distributing, advertising or promoting software or services that have the primary purpose of encouraging or facilitating unsolicited commercial E-mail or spam. A violation of the CAN-SPAM Act of 2003, or any other applicable law regulating email services, constitutes a violation of this policy.
              </li>
              <li>
                Using products and Service to publish, submit/receive, host, transmit, upload/download, post, use, distribute or store child pornography.
              </li>
              <li>
                Engaging in the transmission of pirated software.
              </li>
              <li>
                Exporting, re-exporting, or permitting downloads of any content in violation of the export or import laws of the United States or without all required approvals, licenses and exemptions.
              </li>
              <li>
                Using Service in connection with any criminal, civil or administrative violation of any applicable local, state, provincial, federal, national or international law, treaty, court order, ordinance, regulation or administrative rule.
              </li>
            </ul>

            <h2 className='italic'>Enforcement of Cityside Fiber&apos;s AUP</h2>
            <p>
              Customer&apos;s failure to observe the guidelines set forth in this AUP may result in Cityside Fiber taking actions anywhere from a warning to a suspension or termination of Service. When feasible, Cityside Fiber will provide Customer with a notice of an AUP violation via e-mail or otherwise allowing the Customer to promptly correct such violation.
            </p>
            <p className='bold'>
              Cityside Fiber reserves the right, however, to act immediately and without notice to suspend or terminate affected services in response to a court order or government notice that certain conduct must be stopped or when Cityside Fiber reasonably determines, that the conduct may: (1) expose Cityside Fiber to sanctions, prosecution, civil action or any other liability, (2) cause harm to or interfere with the integrity or normal operations of Cityside Fiber&apos;s network or networks with which Cityside Fiber is interconnected, (3) interfere with another Cityside Fiber Customer&apos;s use of Service or the internet (4) violate any applicable law, rule or regulation, or (5) otherwise present an imminent risk of harm to Cityside Fiber or Cityside Fiber Customers.
            </p>

            <h2 className='italic'>Customer Responsibilities</h2>
            <p>
              As a Customer, You remain solely and fully responsible for the content of any material posted, hosted, downloaded/uploaded, created, accessed or transmitted using the Services. Cityside Fiber has no responsibility for any material created on the Cityside Fiber&apos;s network or accessible using Services.  You are responsible for taking prompt corrective action(s) to remedy a violation of AUP and to help prevent future violations.
            </p>
            <p>
              As a Customer, it is your responsibility to secure your computer and network equipment so that it is not subject to external threats such as viruses, spam, and other methods of intrusion. In all cases, You are solely responsible for the security of any device You choose to connect to the Service, including any data stored or shared on that device. You are responsible for any misuse of the Service, even if the misuse was committed without your authorization. Therefore, You must take steps to ensure that others do not use your computer or network to gain unauthorized access to the Service or to use the Service in an unauthorized manner.
            </p>

            <h2 className='italic'>Questions?</h2>
            <p>
              You may contact Cityside Fiber if it is unsure of whether any contemplated use or action is permitted.
            </p>
          </section>
        </div>
      </div>
      
    </>
  )
};

export default AUPPage;