import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';

import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/multifamily-m.jpg';
import heroImageD from '../../images/multifamily-d.jpg';
import formImageM from '../../images/mo-form-m.jpg';
import formImageD from '../../images/mo-form-d.jpg';
import surveyImageM from '../../images/mo-survey-m.jpg';
import surveyImageD from '../../images/mo-survey-d.jpg';
import MOManagedWifi from './MOManagedWifi/MOManagedWifi';
import Button from '../../components/Button/Button';
import FormContainer5050 from '../../components/FormContainer5050/FormContainer5050';
import styles from './MultiFamilyOwnersPage.module.scss';

import APIService from '../../lib/api.service';

const FormImageContent = (): JSX.Element => (
    <>
        <h2 className='headline__h2 color--white'>
            Complex wide managed WiFi
        </h2>

        <Button
            href='mailto: hello@citysidefiber.com'
            size='lg'
            className='bg-color--orange color--dark-blue focus-contrast'
            fullWidth={true}
        >
            hello@citysidefiber.com
        </Button>

        <h4 className='headline__h4 color--white' data-desktop-large>
            Bulk agreements for simplified service
        </h4>
    </>
)

const MultiFamilyOwnersPage = (): JSX.Element => {
    const formRef = useRef<HTMLFormElement | null>(null);
    const [showAnim, setShowAnim] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [formData, setFormData] = useState<FormData>();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setShowAnim(true); // Set spinner

        let data = {};
        for (const pair of (formData as FormData).entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
            data[`${pair[0]}`] = `${pair[1]}`;
        }

        data["datestamp"] = new Date().toJSON().slice(0, 10); // "YYYY-MM-DD"
        data["address"] = `${data["street"]}, ${data["city"]}, ${data["state"]}`;
        data["UTYPE"] = "M";
        data["phone"] = data["phone"].replaceAll("-", "").trim() // Sanitize to "XXXXXXXXXX"

        data["vserviceloc"] = null; // Pulled from previous form
        data["caccount"] = null;
        data["campaign"] = null;
        data["servicelevel"] = null;
        data["serviceable"] = "No";

        const submit = await APIService.postContactInfo(JSON.stringify(data));
        setShowAnim(false);
        setShowForm(false);

        // TODO: check for bad submission?
        // if (submit.status === 201) {
        //     setShowSubmissionSuccess(true);
        // } else {
        //     setShowOutsideServiceArea(true);
        // }
    }

    const onChange = () => {
        if (formRef.current) {
            setFormData(new FormData(formRef.current));
        }
    }

    useEffect(() => {
        if (formRef.current) {
            setFormData(new FormData(formRef.current));
        }
    }, [formRef.current])

    return (
        <>
        <Helmet>
            <title>{BASE_PAGE_TILE}: Business Services</title>
        </Helmet>
        <div className='bg-color--ui-grey-6'>
            <Hero
            imageM={heroImageM}
            imageD={heroImageD}
            imageDesc='exterior of top two floors of moderm apartment building'
            designElement='wild-circle'
            >
            <h1 className='headline__h1'>
                Power<br />your<br />building<br />on <span className='color--orange'>fiber</span>
            </h1>
            </Hero>
            <MOManagedWifi />
            <section className={styles.surveySection}>
            <picture>
                <source media="(min-width: 720px)" srcSet={surveyImageD} />
                <img
                    src={surveyImageM}
                    alt='appartment building exterior'
                    title='appartment building exterior'
                    aria-label='appartment building exterior'
                    width="100"
                    height="100"
                    loading="eager"
                />
            </picture>
            <div>
                <h2 className='headline__h2 color--white'>
                    Fiber broadband increases rental and property values in MDU&apos;s by 8% and 2.8%, respectively, adding 11% to NOI per unit on average
                </h2>

                <h3 className='headline__h3 color--white' data-subtitle-alt>
                    * survey by RVA, LLC (2016)
                </h3>
            </div>
            
            </section>

            {showForm &&
            <FormContainer5050 
                imageD={formImageD} 
                imageM={formImageM}
                imageDesc='person wearing call center headset'
                formPosition='right'
                designElPosition='top'
                imageFullWidth={false}
                imageBGColor='faded-orange'
                imageContent={<FormImageContent />}
                loadingAnimationText='Submiting interest...'
                showLoadingAnimation={showAnim}
            >

            <form id='form' ref={formRef} onSubmit={onSubmit} onChange={onChange}>
            <h3 className='headline__h3 color--dark-blue'>
                Submit your interest
            </h3>

            <div className='multi-column-container'>
                <div className='input-container'>
                    <label htmlFor='firstname'>First Name</label>
                    <input 
                        id='firstname'
                        name='firstname'
                        type='text'
                        required
                    />
                </div>

                <div className='input-container'>
                    <label htmlFor='lastname'>Last Name</label>
                    <input 
                        id='lastname'
                        name='lastname'
                        type='text'
                        required
                    />
                </div>

                <div className='input-container'>
                    <label htmlFor='email'>Email</label>
                    <input 
                        id='email'
                        name='email'
                        type='email'
                        required
                    />
                </div>
            </div>

            <div className='input-container'>
                <label htmlFor='street'>Street Address</label>
                <input 
                    id='street'
                    name='street'
                    type='text' 
                    autoComplete='off' 
                    placeholder="Steet Address" 
                    required
                />
            </div>

            <div className='multi-column-container'>
                <div className='input-container'>
                    <label htmlFor='city'>City</label>
                    <input 
                        id='city'
                        name='city'
                        type='text' 
                        required
                    />
            </div>

                <div className='select-container'>
                    <label htmlFor='state'>State</label>
                    <select id='state' name='state' defaultValue='CA' required>
                    <option value='CA'>
                        California
                    </option>
                    </select>
                </div>
            </div>

            <div className='multi-column-container'>
                <div className='input-container'>
                    <label htmlFor='communityname'>Apartment Name</label>
                    <input 
                    id='businessname'
                    name='businessname'
                    type='text' 
                    placeholder='Building / Complex Name'
                    required
                    />
                </div>

                <div className='input-container'>
                    <label htmlFor='ucount'>Unit Count</label>
                    <input 
                    id='ucount'
                    name='ucount'
                    type='number' 
                    placeholder='Number of Units in Complex'
                    required
                    />
                </div>
            </div>

            <div className='input-container'>
                <label htmlFor='phone'>Phone</label>
                <input 
                    id='phone'
                    name='phone'
                    type='tel' 
                    pattern='[0-9]{3}-?[0-9]{3}-?[0-9]{4}'
                    placeholder='XXX-XXX-XXXX'
                    required
                />
            </div>            

            <Button
                type='submit'
                className='btn--lg-extra-padding'
                size='md-to-lg'
                fullWidth={true}
                >
                Submit
            </Button>
            </form>
            </FormContainer5050>
            }

            {!showForm &&
            <FormContainer5050 
                imageD={formImageD} 
                imageM={formImageM}
                imageDesc='person wearing call center headset'
                formPosition='right'
                designElPosition='top'
                imageFullWidth={false}
                imageBGColor='faded-orange'
                imageContent={<FormImageContent />}
                loadingAnimationText='Submiting interest...'
                showLoadingAnimation={showAnim}
            >
            <form>
                <h1 className='headline__h1' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>thanks for<br />your interest!</span>
                </h1>
                <p>
                    Our Concierge team will reach out with more information shortly...
                </p>
            </form>
            </FormContainer5050>
            }
        </div>
        </>
    )
};

export default MultiFamilyOwnersPage;