import { AppRoutes } from "./types";

export const ENV = process.env.NODE_ENV;
export const VETRO_API_DOMAIN = process.env.REACT_APP_VETRO_API_DOMAIN;
export const VETRO_API_TOKEN = process.env.REACT_APP_VETRO_API_TOKEN;
export const CLEARCO_WIDGET_CODE = process.env.REACT_APP_CLEARCO_WIDGET_CODE;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const THE_SECRET_CODE = process.env.REACT_APP_THE_SECRET_CODE;
export const BASE_PAGE_TILE = 'Cityside Fiber | Built for Orange County';

export const ROUTES: AppRoutes = {
    landing: {
        path: '/',
        loginGuard: false
    },
    aboutUs: {
        path: '/about-us',
        loginGuard: false
    },
    associations: {
        path: '/associations',
        loginGuard: false
    },
    blog: {
        path: '/blog',
        loginGuard: false
    },
    businessServices: {
        path: '/business-services',
        loginGuard: false
    },
    checkAvailability: {
        path: '/check-availability',
        loginGuard: false
    },
    construction: {
        path: '/construction',
        loginGuard: false
    },
    enterpriseServices: {
        path: '/enterprise-services',
        loginGuard: false
    },
    joinUs: {
        path: '/join-us',
        loginGuard: false
    },
    multiFamilyOwners: {
        path: '/multifamily-owners',
        loginGuard: false
    },
    streamingTV: {
        path: '/streaming-tv',
        loginGuard: false
    },
    press: {
        path: '/press',
        loginGuard: false
    },
    wholeHomeWifi: {
        path: '/wholehome-wifi',
        loginGuard: false
    },
    tustin: {
        path: '/tustin',
        loginGuard: false
    },
    homeInternet: {
        path: '/homeinternet',
        loginGuard: false
    },
    internet: {
        path: '/internet',
        loginGuard: false
    },
    whyFiber: {
        path: '/why-fiber',
        loginGuard: false
    },
    policies: {
        path: '/policies',
        loginGuard: false
    },
    privacy: {
        path: '/privacy-policy',
        loginGuard: false
    },
    support: {
        path: '/support',
        loginGuard: false
    },
    terms: {
        path: '/residential-service-terms',
        loginGuard: false
    },
    websiteTerms: {
        path: '/website-terms',
        loginGuard: false
    },
    aup: {
        path: '/aup',
        loginGuard: false
    },
    dmca: {
        path: '/dmca-policy',
        loginGuard: false
    },
    beta: {
        path: '/beta-agreement',
        loginGuard: false
    },
}