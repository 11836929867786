import React from 'react';
import styles from './MOManagedWifi.module.scss';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import Button from '../../../components/Button/Button';
import IconsBlocks from '../../../components/IconBlocks/IconBlocks';


const MOManagedWifi = (): JSX.Element => {
  
  return (
    <section className={`grid-12 ${styles.container}`}>
      <div className={styles.inner}>

        <div className={styles.head}>
          <h2 className='headline__h2 color--dark-blue'>
            Managed WiFi & whole complex solutions for multifamily owners
          </h2>

          <h4 className='headline__h4 color--med-blue'>
          1 Gig+ fiber internet to every resident means...
          </h4>
        </div>

        <IconsBlocks
          data={[
            {
              icon: 'clock',
              iconColor: 'med-blue',
              text: 'Future proof infrastructure'
            },
            {
              icon: 'piggy-bank',
              iconColor: 'yellow',
              text: 'Increased property value'
            },
            {
              icon: 'wrench',
              iconColor: 'orange',
              text: 'Less maintenance'
            },
            {
              icon: 'building',
              iconColor: 'dark-blue',
              text: 'Easy, less intrusive install'
            },
            {
              icon: 'chart-line',
              iconColor: 'bright-blue',
              text: 'Less turnover, happier tenants'
            }
          ]}
        />

        <Button
          href='#form'
          size='md'
          className='margin-auto bg-color--bright-blue color--dark-blue'
        >
          Submit my building info
        </Button>
        
      </div>
    </section>
  )
};

export default MOManagedWifi;