import React, { useRef } from 'react';
import styles from './LPPackages.module.scss';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import Button from '../../../components/Button/Button';
import OfferCard from '../../../components/OfferCard/OfferCard';
import OfferCardsContainer from '../../../components/OfferCardsContainer/OfferCardsContainer';
import { ROUTES } from '../../../lib/constants';

const LPPackages = (): JSX.Element => {
  const ref1 = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const ref3 = useRef<HTMLDivElement | null>(null);

  return (
    <section className={`grid-12 ${styles.container}`}>
      <div>
        <h2 className='headline__h2 color--yellow'>
          WholeHome<br />fiber services
        </h2>

        <h3 className='headline__h4 color--white' data-desktop-large>
          WiFi6 connectivity to every<br />part of your home
        </h3>

        <div className={styles.lists}>
          <div>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              Equipment included
            </p>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              Installation included
            </p>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              No service fees
            </p>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              No data caps
            </p>
          </div>

          <div>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              No long term contracts
            </p>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              No early exit fees
            </p>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              No promo gimmicks
            </p>
            <p>
              <SVGIcon id='check' color='bright-blue' />
              <span>No gimmicks at all, <span className='italic'>really</span></span>
            </p>
          </div>
        </div>

        <h3 className='headline__h4 color--white' data-desktop-large>
          Now isn&#8217;t that refreshing?
        </h3>

        <Button
          size='md-to-lg'
          className='bg-color--bright-blue color--dark-blue btn--md-extra-padding btn--lg-extra-padding focus-contrast'
          href={ROUTES.checkAvailability.path}
          target='_self'
         >
          Sign up today
        </Button>
      </div>

      <div>
        <OfferCardsContainer>
          <OfferCard
            ref={ref1}
            headline='The Point'
            subhead='1 Gig Connect'
            price='$80'
            borderColor='bright-blue'
            reveal={true}
          >
            <p>
              For the old school browser, smaller spaces, and fewer devices.
              An internet experience perfect for streaming with ease and priced at a fantastic value.
            </p>

            <ul>
              <li>1,000 MBPS (that&apos;s 1 Gig)</li>
              <li>1 gigabit upload & download</li>
              <li>Includes a WiFi6 router</li>
            </ul>
          </OfferCard>

          <OfferCard
            ref={ref2}
            headline='The Wedge'
            subhead='2 Gig Connect'
            price='$95'
            borderColor='bright-blue'
            reveal={false}
          >
            <p>
              For the active user, a great package for larger residences running multiple devices simultaneously (computers, phones, tablets, TVs, and more).
            </p>

            <ul>
              <li>2,000 MBPS (that&rsquo;s 2 Gigs)</li>
              <li>2 gigabit upload & download</li>
              <li>Includes 1 WiFi Extender</li>
              <li>Includes a WiFi6 router</li>
            </ul>
          </OfferCard>

          <OfferCard
            ref={ref3}
            headline='The Monarch'
            subhead='5 Gig Connect'
            price='$195'
            borderColor='bright-blue'
            reveal={false}
          >
            <p>
              For larger spaces, the gamer, influencer, business professional, and family.
              Your home and internet experience is connected to the world with the highest speed available.
            </p>

            <ul>
              <li>5,000 MBPS (that&rsquo;s 5 Gigs)</li>
              <li>5 gigabit upload & download</li>
              <li>Includes a WiFi6 router</li>
              <li>Includes up to 2 WiFi extender devices for WholeHome coverage – even outside!</li>
            </ul>
          </OfferCard>
        </OfferCardsContainer>

        <div className={styles.btmContent}>
          <p>Looking for streaming TV packages? We have that too.</p>
          <Button
            className='bg-color--bright-blue color--dark-blue focus-contrast'
            href='http://streaming.citysidefiber.com/start'
            target='_self'
            size='sm'
          >
            Learn more
          </Button>
        </div>

      </div>
    </section>
  )
};

export default LPPackages;