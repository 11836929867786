import React, { ReactElement } from 'react';
import styles from './Hero.module.scss';
import { Color } from '../../lib/types';

interface IProps {
  children: ReactElement | ReactElement[];
  imageM: string;
  imageD: string;
  imageDesc?: string;
  bgColor?: Color;
  objectPosition?: string;
  designElement: 'wild-circle' | 'circle-of-dots' | 'orange-radient-lines' | 'blue-radient-lines';
  designElementColor?: Color;
  sticker?: ReactElement;
}
const Hero = (props: IProps): JSX.Element => {

  return (
    <section className={styles.hero}>
      <div className={`grid-12 ${styles.container}`}>
        <div className={styles.innerContainer}>

          <div className={`${styles.headlineWrap}${props.bgColor ? ` bg-color--${props.bgColor}` : ''}`}>
            <div 
              className={`${styles.designElement}${props.designElementColor ? ` bg-color--${props.designElementColor}` : ''}`} 
              data-type={props.designElement} 
              data-custom-color={!!props.designElementColor}
            ></div>
            { props.children }
          </div>

          <div className={styles.imageWrap}>
            <picture>
              <source media="(min-width: 720px)" srcSet={props.imageD} />
              <img style={{objectPosition: props.objectPosition}}
                src={props.imageM}
                alt={props.imageDesc}
                title={props.imageDesc}
                aria-label={props.imageDesc}
                width="100"
                height="100"
                loading="eager"
              />
            </picture>
          </div>

          { props.sticker }
        </div>
      </div>
    </section>
  )
};

export default Hero