import React, { ChangeEvent, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './WHManagedWifi.module.scss';
import imageSingleM from '../../../images/wh-managed-wifi-single-m.jpg';
import imageSingleD from '../../../images/wh-managed-wifi-single-d.jpg';
import devicesSingleM from '../../../images/wh-managed-wifi-single-devices-m.png';
import devicesSingleD from '../../../images/wh-managed-wifi-single-devices-d.png';
import imageAptM from '../../../images/wh-managed-wifi-apt-m.jpg';
import imageAptD from '../../../images/wh-managed-wifi-apt-d.jpg';
import devicesAptM from '../../../images/wh-managed-wifi-apt-devices-m.png';
import devicesAptD from '../../../images/wh-managed-wifi-apt-devices-d.png';
import imageCondoM from '../../../images/wh-managed-wifi-condo-m.jpg';
import imageCondoD from '../../../images/wh-managed-wifi-condo-d.jpg';
import devicesCondoM from '../../../images/wh-managed-wifi-condo-devices-m.png';
import devicesCondoD from '../../../images/wh-managed-wifi-condo-devices-d.png';

const WHManagedWifi = (): JSX.Element => {
  const [inputID, setInputID] = useState('single');
  const { ref, inView } = useInView({
    /* threshold: [0.85, 1], */
    delay: 500,
    root: document.querySelector(styles.container),
    rootMargin: '-55% 0px -45% 0px'
  });

  const onChange = (e: ChangeEvent) => {
    setInputID(e.target.id);
  }
  
  return (
    <section className={styles.container}>
      <div className='grid-12' data-wide={inView}>
        <div className={styles.content}>

          <h2 className='headline__h2 color--white'>
            What does WholeHome<br />managed WiFi mean?
          </h2>

          <p className='color--white'>
            Putting you back in control of your home&apos;s connectivity. All of your home. All of your devices. 
            From Zoom calls in the home office to streaming the game downstairs to even entertaining neighbors on the patio. 
            All at once. We&apos;ve got you covered, and with your MyCity WiFi app, you&apos;re in charge of it all.
          </p>

          <div className={styles.btnWrap}>
              <label htmlFor='single' data-checked={inputID === 'single'}>
                Single family home
                <input
                  type='radio'
                  id='single'
                  value='single'
                  onChange={onChange}
                  checked={inputID === 'single'}
                />
              </label>

              <label htmlFor='apt' data-checked={inputID === 'apt'}>
                Apartment complex
                <input
                  type='radio'
                  id='apt'
                  value='apt'
                  onChange={onChange}
                  checked={inputID === 'apt'}
                />
              </label>

              <label htmlFor='condo' data-checked={inputID === 'condo'}>
                Condo complex
                <input
                  type='radio'
                  id='condo'
                  value='condo'
                  onChange={onChange}
                  checked={inputID === 'condo'}
                />
              </label>
          </div>
        </div>

        <div className={styles.imgWrap} ref={ref} data-wide={inView}>
            {
              inputID === 'single' && (
                <>
                  <picture>
                    <source media="(min-width: 720px)" srcSet={imageSingleD} />
                    <img
                      src={imageSingleM}
                      alt="blueprint of single family home"
                      title="blueprint of single family home"
                      aria-label="blueprint of single family home"
                      width="100"
                      height="100"
                      loading="eager"
                    />
                  </picture>
                  <picture>
                    <source media="(min-width: 720px)" srcSet={devicesSingleD} />
                    <img
                      src={devicesSingleM}
                      alt="wifi devices of single family home"
                      title="wifi devices of single family home"
                      aria-label="wifi devices of single family home"
                      width="100"
                      height="100"
                      loading="eager"
                    />
                  </picture>
                </>
              )
            }

            {
              inputID === 'apt' && (
                <>
                  <picture>
                    <source media="(min-width: 720px)" srcSet={imageAptD} />
                    <img
                      src={imageAptM}
                      alt="blurprint of apartment"
                      title="blurprint of apartment"
                      aria-label="blurprint of apartment"
                      width="100"
                      height="100"
                      loading="eager"
                    />
                  </picture>
                  <picture>
                    <source media="(min-width: 720px)" srcSet={devicesAptD} />
                    <img
                      src={devicesAptM}
                      alt="wifi devices of apartment complex"
                      title="wifi devices of apartment complex"
                      aria-label="wifi devices of apartment complex"
                      width="100"
                      height="100"
                      loading="eager"
                    />
                  </picture>
                </>
              )
            }

            {
              inputID === 'condo' && (
                <>
                  <picture>
                    <source media="(min-width: 720px)" srcSet={imageCondoD} />
                    <img
                      src={imageCondoM}
                      alt="blurprint of condo"
                      title="blurprint of condo"
                      aria-label="blurprint of condo"
                      width="100"
                      height="100"
                      loading="eager"
                    />
                  </picture>
                  <picture>
                    <source media="(min-width: 720px)" srcSet={devicesCondoD} />
                    <img
                      src={devicesCondoM}
                      alt="wifi devices of condo complex"
                      title="wifi devices of condo complex"
                      aria-label="wifi devices of condo complex"
                      width="100"
                      height="100"
                      loading="eager"
                    />
                  </picture>
                </>
              )
            }

            <div className={styles.radial} data-index="1" data-first data-type={inputID}></div>
            <div className={styles.radial} data-index="1" data-second data-type={inputID}></div>
            <div className={styles.radial} data-index="2" data-first data-type={inputID}></div>
            <div className={styles.radial} data-index="2" data-second data-type={inputID}></div>
          </div>
      </div>
    </section>

  )
};

export default WHManagedWifi;