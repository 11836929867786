import { API_DOMAIN } from "./constants";

export default abstract class APIService {

    private static getHeaders(): {[key: string]: string} {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }

    public static async postContactInfo(data: string): Promise<any> {
        const timeoutLength = 10000; // in ms, equals 10 seconds

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeoutLength);

        const resp = await fetch(`${API_DOMAIN}/api/contactus/`,
        {
            method: 'POST',
            headers: this.getHeaders(),
            body: data,
            signal: controller.signal
        });
        const result = await resp;

        clearTimeout(timeoutId);
        return result;
    }
}