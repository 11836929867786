import React from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './Footer.module.scss';
import logo from '../../images/logo-alt.svg'
import { Link } from 'react-router-dom';
import { ROUTES } from '../../lib/constants';

const Footer = (): JSX.Element => {

  return (
    <footer className={styles.footer}>
      <div className={`grid-12 ${styles.content}`}>
        <Link to='/' className={`${styles.logo} focus-contrast`} aria-label='go to home page'>
          <img
            src={logo}
            alt='CitySide Fiber logo'
            title='CitySide Fiber logo'
            aria-label='CitySide Fiber logo'
            width='173'
            height='65'
            loading='lazy'
          />
        </Link>

        <div className={styles.info}>
          <div>
            <div className={styles.cat}>
              <p className={styles.label}>Phone</p>
              <a className='focus-contrast' href='tel: 1 (949) 652-2855'>
                949-779-CITY (2489)
              </a>
            </div>

            <div className={styles.cat}>
              <p className={styles.label}>Email</p>
              <a className='focus-contrast' href='mailto: concierge@citysidefiber.com'>
                concierge@citysidefiber.com
                </a>
            </div>

            <div className={styles.cat}>
              <p className={styles.label}>Headquarters</p>
              <address>100 Spectrum Center Dr, Irvine, CA 92618</address>
            </div>
          </div>

          <div>
            <div className={styles.cat}>
              <p className={styles.label}>Social</p>
              <div className={styles.socialWrap}>
                <a className='focus-contrast'
                  href='https://www.linkedin.com/company/cityside-fiber'
                  target='_blank'
                  rel='noreferrer'
                  aria-label={'Visit Cityside Fiber\'s LinkedIn page'}
                >
                  <SVGIcon id='linkedin' color='white' />
                </a>
                <a className='focus-contrast'
                  href='https://www.facebook.com/citysidefiber/'
                  target='_blank'
                  rel='noreferrer'
                  aria-label={'Visit Cityside Fiber\'s Facebook page'}
                >
                  <SVGIcon id='facebook' color='white' />
                </a>
                <a className='focus-contrast'
                  href='https://www.instagram.com/citysidefiber/'
                  target='_blank'
                  rel='noreferrer'
                  aria-label={'Visit Cityside Fiber\'s on Instagram'}
                >
                  <SVGIcon id='instagram' color='white' />
                </a>
              </div>
            </div>

            <div className={styles.cat}>
              <p className={styles.label}>Company</p>
              {/* <Link className='focus-contrast' to={ROUTES.checkAvailability.path}>
                Check my Availability
              </Link> */}
              <a className='focus-contrast' href={ROUTES.checkAvailability.path} target="_self">
                Check my Availability
              </a>
              {/* <Link className='focus-contrast' to={ROUTES.support.path}>
                Support
              </Link> */}
              <Link className='focus-contrast' to={ROUTES.press.path}>
                In the News
              </Link>
              <Link className='focus-contrast' to={ROUTES.policies.path}>
                Policies
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.copywrite}>
          @ Copyright Cityside Fiber All Rights Reserved
        </div>
      </div>

      <div className={styles.line}></div>
    </footer>
  )
};

export default Footer;