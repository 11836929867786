import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/global.scss';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { ROUTES } from './lib/constants';
import LandingPage from './pages/LandingPage/LandingPage';
import WholeHomeWifiPage from './pages/WholeHomeWifiPage/WholeHomeWifiPage';
import StreamingTVPage from './pages/StreamingTVPage/StreamingTVPage';
import PressPage from './pages/PressPage/PressPage';
import CheckAvailabilityPage from './pages/CheckAvailabilityPage/CheckAvailabilityPage';
import WhyFiberPage from './pages/WhyFiberPage/WhyFiberPage';
import BusinessServicesPage from './pages/BusinessServicesPage/BusinessServicesPage';
import MultiFamilyOwnersPage from './pages/MultiFamilyOwnersPage/MultiFamilyOwnersPage';
import AssociationsPage from './pages/AssociationsPage/AssociationsPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import EnterpriseServicesPage from './pages/EnterpriseServicesPage/EnterpriseServicesPage';
import ConstructionPage from './pages/ConstructionPage/ConstructionPage';
import JoinUsPage from './pages/JoinUsPage/JoinUsPage';
import BlogPage from './pages/BlogPage/BlogPage';
import PrivacyPage from './pages/LegalPages/PrivacyPage';
import PoliciesPage from './pages/LegalPages/PoliciesPage';
import DMCAPage from './pages/LegalPages/DMCAPage';
import BetaPage from './pages/LegalPages/BetaPage';
import WebsiteTermsPage from './pages/LegalPages/WebsiteTermsPage';
/* import SupportPage from './pages/LegalPages/SupportPage'; */
import TermsPage from './pages/LegalPages/TermsPage';
import AUPPage from './pages/LegalPages/AUPPage';
import NotFound from './pages/NotFound/NotFound';
/* import reportWebVitals from './reportWebVitals'; */

const baseUrl: string = document.getElementsByTagName('base')[0]?.getAttribute('href') || '/';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  /* <React.StrictMode> */
  <BrowserRouter basename={baseUrl}>
    <ScrollToTop>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={<LandingPage />}/>
          <Route path={ROUTES.aboutUs.path} element={<AboutUsPage />} />
          <Route path={ROUTES.associations.path} element={<AssociationsPage />} />
          <Route path={ROUTES.businessServices.path} element={<BusinessServicesPage />} />
          <Route path={ROUTES.checkAvailability.path} element={<CheckAvailabilityPage />} />
          <Route path={ROUTES.construction.path} element={<ConstructionPage />} />
          <Route path={ROUTES.enterpriseServices.path} element={<EnterpriseServicesPage />} />
          <Route path={ROUTES.joinUs.path} element={<JoinUsPage />} />
          <Route path={ROUTES.blog.path} element={<BlogPage />} />
          <Route path={ROUTES.multiFamilyOwners.path} element={<MultiFamilyOwnersPage />} />
          <Route path={ROUTES.streamingTV.path} element={<StreamingTVPage />} />
          <Route path={ROUTES.press.path} element={<PressPage />} />
          
          { /* Point to the same page for marketing tracking purposes */ }
          <Route path={ROUTES.wholeHomeWifi.path} element={<WholeHomeWifiPage />} />
          <Route path={ROUTES.internet.path} element={<WholeHomeWifiPage />} />
          <Route path={ROUTES.tustin.path} element={<WholeHomeWifiPage />} />
          <Route path={ROUTES.homeInternet.path} element={<WholeHomeWifiPage />} />

          <Route path={ROUTES.whyFiber.path} element={<WhyFiberPage />} />
          {/* <Route path={ROUTES.support.path} element={<SupportPage />} /> */}

          { /* Privacy policies */ }
          <Route path={ROUTES.policies.path} element={<PoliciesPage />} />
          <Route path={ROUTES.privacy.path} element={<PrivacyPage />} />
          <Route path={ROUTES.terms.path} element={<TermsPage />} />
          <Route path={ROUTES.websiteTerms.path} element={<WebsiteTermsPage />} />
          <Route path={ROUTES.aup.path} element={<AUPPage />} />
          <Route path={ROUTES.dmca.path} element={<DMCAPage />} />
          <Route path={ROUTES.beta.path} element={<BetaPage />} />
          <Route path='*' element={<NotFound />}/>
        </Route>
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
  /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
