import React, { useState } from 'react';
import './Video.scss';
import { Color } from '../../lib/types';
import SVGIcon from '../SVGIcon/SVGIcon';

interface IProps {
  src: string;
  poster?: string;
  iconColor?: Color;
}

const Video = ({src, poster, iconColor}: IProps): JSX.Element => {
  const [ showPoster, setShowPoster ] = useState(true);

  const onClick = () => {
    if (showPoster) {
      setShowPoster(false);
    }
  }
  
  return (
    <div className='video-wrap' tabIndex={0} onClick={onClick} aria-label='Play video'>
      {
        !showPoster && src.includes('youtube.com') &&
          <iframe 
            width='1280' 
            height='720' 
            src={src}
            title='YouTube video player'
            frameBorder='0' 
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' 
            allowFullScreen>
          </iframe>
      }

      {
        !showPoster && !src.includes('youtube.com') &&
          <video 
            width='1280' 
            height='720' 
            src={src}
            title='Video player'
            controls 
            autoPlay
            playsInline>
          </video>
      }

      {
        showPoster &&
          <img 
            data-show={showPoster}
            className='video-poster'
            src={poster}
            aria-label='video poster image'
            width='100' 
            height='100'
            loading='lazy'
          />
      }
      
      <div className='video-play-btn' data-show={showPoster}>
        <SVGIcon id='play' color={iconColor || 'white'} />
      </div>
    </div>
  )
};

export default Video;