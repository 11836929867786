import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE, ROUTES } from '../../lib/constants';
import styles from './LegalPages.module.scss'


const PoliciesPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Privacy Policy</title>
      </Helmet>
      <div className='grid-12'>
        <div className={styles.container}>
            <center>
              <h1 className='headline__h1 color--dark-blue'>
                Our Policies
              </h1>
              <section className={styles.content}>
                <p className='italic'>
                  Please review the policies, terms, and conditions at the links provided below 
                </p>

                <h1>
                  <a href={ROUTES.websiteTerms.path} target="_self">
                    <span className='header__nav-link'>Website Terms of Use</span>
                  </a>
                </h1><br/>

                <h1>
                  <a href={ROUTES.privacy.path} target="_self">
                    <span className='header__nav-link'>Privacy Policy</span>
                  </a>
                </h1><br/>

                <h1>
                  <a href={ROUTES.aup.path} target="_self">
                    <span className='header__nav-link'>Acceptable Use Policy</span>
                  </a>
                </h1><br/>

                <h1>
                  <a href={ROUTES.terms.path} target="_self">
                    <span className='header__nav-link'>Residential Service Terms & Conditions</span>
                  </a>
                </h1><br/>

                <h1>
                  <a href={ROUTES.dmca.path} target="_self">
                    <span className='header__nav-link'>DMCA Policy</span>
                  </a>
                </h1><br/>

                <h1>
                  <a href={ROUTES.beta.path} target="_self">
                    <span className='header__nav-link'>Beta Agreement</span>
                  </a>
                </h1><br/>
              </section>
            </center>
        </div>
      </div>
      
    </>
  )
};

export default PoliciesPage;