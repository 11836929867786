import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';

const NotFound = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Page Not Found</title>
      </Helmet>
      <div className='grid-12'>
        <h1 className='headline__h1' style={{gridColumnEnd: 'span 12', textAlign: 'center', padding: '64px 0 34vh'}}>
          404 <span className='color--dark-blue'>Page Not Found</span>
        </h1>
      </div>
    </>
  )
};

export default NotFound;