import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './LegalPages.module.scss'


const PrivacyPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Privacy Policy</title>
      </Helmet>
      <div className='grid-12'>
        <div className={styles.container}>
          <h1 className='headline__h1 color--dark-blue'>
            Privacy Policy
          </h1>
          <section className={styles.content}>
            <p className='italic'>
              Last Updated: July 2023
            </p>

            <p>
              Thank you for choosing Cityside Networks, LLC (dba Cityside Fiber). (&quot;Cityside Fiber&quot;). Cityside Fiber does not sell your personal information, but we still have to tell you what we collect from you and how Cityside Fiber uses it. Cityside Fiber may update this policy from time to time.  Continued use of the website as well as our products and services will be deemed acknowledgment and acceptance of the then current privacy policy (&quot;Policy&quot;). You are responsible for making sure all users under your account understand and agree to this Policy. 
            </p>
            <p>
              If you are a Cityside Fiber business customer, we may have written Product or Service agreements that contain specific provisions about confidentiality, security or handling of information. When one of those agreements differs from or conflicts with this Policy, the terms of those agreements will apply instead. 
            </p>

            <h2>Collection of Personal Information</h2>
            <p>
              Cityside Fiber collects specific types of personal information directly from you. Depending on how you interact with our website, we may collect the following personal information about you: 
            </p>
            <ul>
              <li>Identity Data, which may include first name, last name.</li>
              <li>Contact Data, which may include delivery/service address, email address, and phone numbers.</li>
              <li>Profile Data, which may include username and password, purchases or orders made by you, customer service requests, your interests, and preferences in receiving marketing and non-marketing information from Cityside Fiber.</li>
              <li>Financial Data, which includes bank and credit/debit card details.</li>
              <li>Transaction Data, which includes details about payments to and from you and other details of services you buy from Cityside Fiber.</li>
              <li>Website Technical Data, which may include the following information if you use our online portal and services: Internet protocol (IP) address, browser type and version, location, browser plug-in types, operating platform, and the ways in which you use or interact with Cityside Fiber&apos;s online portal and services.</li>
              <li>Customer Technical Data, which may include data about throughput delivered to you over time, service connectivity, latency, quality metrics, sky obstruction data, device orientation and location, and the public IP address information assigned to you over time.</li>
            </ul>
            
            <h2>Personal Information Use</h2>
            <p>
              Cityside Fiber only uses your personal information for specific business purposes related to the products and services that Cityside Fiber provides. 
            </p>
            <p>
              The following are descriptions of the purposes for which Cityside Fiber may use certain personal information: 
            </p>
            <ol>
              <li>
                Personal Information Concerned
                <ul>
                  <li>To process and deliver customer orders</li>
                  <li>To manage payments, fees and charges</li>
                  <li>To manage relationship with customers, such as communicating with them about our services</li>
                  <li>To administer and protect our services</li>
                  <li>To detect, prevent, or otherwise address fraud, security or technical issues, including by monitoring and enforcing compliance with policies</li>
                  <li>To defend our interests in the event of a dispute</li>
                  <li>To comply in good faith with applicable laws, legal processes, and lawful government requests</li>
                </ul>
              </li>
              <li>
                Identity, Contact, Profile, Financial, Transaction, Website Technical Data and Customer Technical Data
                <ul>
                  <li>To understand what may be of interest to you</li>
                  <li>To deliver relevant offers and other content to you and measure and understand the effectiveness of the content</li>
                  <li>To ask and enable a customer to take a survey</li>
                </ul>
              </li>
              <li>
                Identity, Contact, Profile, Transaction, Website Technical Data, Customer Technical Data
                <ul>
                  <li>To use data analytics to debug, optimize, and improve our services, marketing, and customer experiences</li>
                </ul>
              </li>
            </ol>

            <h2>Sharing of Personal Information</h2>
            <p>Cityside Fiber does not sell your personal information, but may share your personal information with the following categories of people:</p>
            <ul>
              <li>Service Providers: We may share your personal information with our affiliates and service providers for the purposes we outline above. For example, we may rely on service providers to host and maintain our online services, perform backup and storage services, process payments, and transmit messages. Our service providers are currently located and process personal information in United States.</li>
              <li>Government Agencies, Regulators and Professional Advisors: Where required by applicable law, we may also need to transfer your personal information to government agencies and regulators (e.g., tax authorities, courts, and government authorities).</li>
              <li>Organizations Involved in Business Transfers: If Cityside Fiber sells, merges or reorganizes, we expect that the personal information that Cityside Fiber has collected will be transferred to the surviving or acquiring entity in accordance with applicable law.</li>
            </ul>

            <h2>Protecting Personal Information</h2>
            <p>Cityside Fiber uses technological, physical and administrative procedures to protect your personal information from loss, misuse, unauthorized access, disclosure, alteration, and destruction, taking into account the risks involved in the processing and the nature of the personal information.</p>
            <p>Although Cityside Fiber strives to keep information secure, we cannot guarantee that our security measures will prevent every unauthorized attempt to access, use or disclose personal information. We maintain procedures to deal with any suspected breach of personal information, and we will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
            
            <h2>Privacy Rights</h2>
            <p>You may have legal rights to access, update, or erase certain personal information that we have about you, or restrict or object to how we use it. If you have such rights and you make a legitimate request, we will do what you request. To exercise these rights, please contact us using the details in the “Contact Us” section below. For your security, we may need to request specific information from you to help us make sure the request is coming from you and not someone who has no right to receive it.</p>
            
            <h2>Contact Us</h2>
            <p>
              Email: <a href='mailto: privacy@citysidenetworks.com'>privacy@citysidenetworks.com</a><br />
              <address>USPS Mail: Legal Department, Cityside Networks, Inc., 550 W B St., 4th Floor, San Diego, CA 92101</address>
            </p>

            <h2>California Residents Only</h2>
            <p>
              If you are a resident of California, our CCPA Privacy Policy (“CCPA Policy”) also applies to you. Cityside Fiber&apos;s CCPA Policy is as follows:
            </p>
            <p>
              Under the California Consumer Privacy Act of 2018 (“CCPA”), California consumers have the right to request certain information from Cityside Fiber. In particular, you may request twice within a 12-month period that Cityside Fiber disclose the categories of personal information collected about you in the 12 months preceding your request, the categories of sources from which the personal information was collected, the business or commercial purposes for which personal information was collected, and the categories of third parties with whom Cityside Fiber shared your personal information. You may also make a request for the specific pieces of personal information that Cityside Fiber has collected about you. If we are able to provide this information electronically, we will do so.
            </p>
            <p> 
              Additionally, you have the right to request that Cityside Fiber delete your personal information from our systems. Cityside Fiber will take reasonable steps to fulfil your request, however, in certain circumstances we will be unable to delete all of your personal information as Cityside Fiber is lawfully authorized to retain certain categorizes of your personal information for legitimate business and regulatory purposes. In instances where Cityside Fiber is not required to delete of your personal information, Cityside Fiber will notify you of those categories of personal information that we did not delete and will provide an explanation as to why the information could not be deleted.
            </p>
            <p>  
              Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. You may submit any of the requests described in this section by calling us toll-free at 833-318-4646. Please note that Cityside Fiber must be able to verify your identity in order to comply with your requests under this section. To do so, Cityside Fiber will seek to associate the information provided by you when making a request with personal information Cityside Fiber has collected about you previously. If you request for Cityside Fiber to disclose specific pieces of personal information, we will ask you to sign a declaration, to be made under penalty of perjury, stating that you are the consumer that is the subject of your request. This is intended to help Cityside Fiber protect the privacy and security of the personal information we maintain. If you are requesting specific pieces of personal information as the authorized agent of a California consumer, we will ask you also to submit reliable proof that you have been authorized in writing by the consumer to act on such consumer&apos;s behalf. Please note that in order to provide you with any CPNI we will need to further authenticate you in accordance with federal law. Making a verifiable consumer request does not require you to create an account with Cityside Fiber. If Cityside Fiber is unable to appropriately verify your identity, we may ask you to contact us to provide additional information or explain why we are unable to complete your request.
            </p>
            <p>  
              Cityside Fiber strives to respond to verifiable consumer requests within forty-five (45) days of receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. Cityside Fiber does not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If Cityside Fiber determines that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
            </p>
            <p> 
              Cityside Fiber may not and will not discriminate against any California consumer for exercising any of the rights described in this section – including by charging differential pricing. Cityside Fiber does not and does not intend in the future to sell your personal information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as California&apos;s “Shine the Light” law, also allows California residents to request certain information regarding our disclosures in the prior calendar year, if any, of personal information to third parties for their own direct marketing purposes. To make such a request, please contact us at either legal@citysidenetworks.com or Legal Department, Cityside Networks, Inc., 550 W B St., 4th Floor, San Diego, CA 92101, and include your name and the address to which you would like us to respond. Cityside Fiber will attempt to provide you with the requested information within thirty (30) days of receipt.
            </p>
          </section>
        </div>
      </div>
      
    </>
  )
};

export default PrivacyPage;