import React from 'react';
import styles from './StreamingTVExplore.module.scss';
import Button from '../../../components/Button/Button';
import imageM from '../../../images/streaming-tv-explore-m.jpg';
import imageD from '../../../images/streaming-tv-explore-d.jpg';

const StreamingTVExplore = (): JSX.Element => {
  
  return (
    <section className='bg-color--faded-neon-blue'>
      <div className={`grid-12 ${styles.container}`}>

        <h2 className='headline__h2 color--dark-blue'>
          But what if all I know is cable?
        </h2>

        <div className={styles.imgWrap}>
          <picture>
            <source media="(min-width: 720px)" srcSet={imageD} />
            <img
              src={imageM}
              alt="TV remote"
              aria-label="TV remote"
              width="100"
              height="100"
              loading="lazy"
            />
          </picture>
        </div>

        <div className={`main-text ${styles.text}`}>
          <p>
            That&apos;s okay. Cable was built to provide linear TV, and that&apos;s what it does best. 
            If you want to keep your channels and set-top box, that&apos;s great.
          </p>
          <p>
            Fiber was built for the internet, and that&apos;s what we do best. Keep your cable TV and enjoy upgraded super fast fiber internet.
          </p>
          <p>
            Or if you really want to make the switch to streaming and aren&apos;t sure how, we&apos;re there for you. 
            Customize a package built specifically for you, or just browse what the streaming experience looks like.
          </p>
        
          <Button 
            href='https://streaming.citysidefiber.com/'
            target='_self'
            size='md-to-lg'
            className='btn--lg-extra-padding bg-color--bright-blue color--dark-blue'
          >
            Explore the Streaming World
          </Button>
        </div>
      </div>
    </section>
  )
};

export default StreamingTVExplore;