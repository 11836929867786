import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.scss';
import SVGIcon from '../SVGIcon/SVGIcon';
import logo from '../../images/logo.svg';
import NavMenu from './NavMenu';
import Button from '../Button/Button';
import { ROUTES } from '../../lib/constants';

const Header = (): JSX.Element => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [hideSubNav, setHideSubNav] = useState<number>(0);

  const onSignUpFocus = () => {
    setHideSubNav(hideSubNav + 1);
  }

  useEffect(() => {
    /* close menu when location changes */
    setShowMenu(false);
  }, [location]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const onScroll = () => {
      setScrolling(true);
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        document.body.removeEventListener('scroll', onScroll);
        setScrolling(false);
        setTimeout(() => {
          document.body.addEventListener('scroll', onScroll);
        }, 300);
      }, 300);
    }
    document.body.addEventListener('scroll', onScroll);
  }, [setScrolling])
  
  return (
    <header>
      <div className='header' data-scroll={scrolling}>
        <div className='header__left-wrap'>
          <button type='button' 
            className='mobile-menu-btn' 
            onClick={() => setShowMenu(!showMenu)}
            aria-label='toggle mobile menu'
          >
            <SVGIcon 
              id={showMenu ? 'close' : 'menu'} 
              color={showMenu ? 'warm-black' : 'ui-grey-3'} 
            />
          </button>

          <Link className='logo' to='/' aria-label='go to home page'>
            <img 
              src={logo}
              alt='CitySide Fiber logo'
              title='CitySide Fiber logo'
              aria-label='CitySide Fiber logo'
              width='86' 
              height='32' 
              loading='lazy'/>
          </Link>
        </div>

        <NavMenu show={showMenu} hideSubNav={hideSubNav} />

        <div className='header__right-wrap'>
          <Button
            href={ROUTES.checkAvailability.path}
            target='_self'
            size='sm-to-md'
            onFocus={onSignUpFocus}
          >
            Check availability
          </Button>

          <Button
            href='https://citysidefiber.broadbandportal.net/?showLogin=true' 
            target='_self'
            size='sm-to-md'
            onFocus={onSignUpFocus}
            secondary={true}
          >
            Login
          </Button>
        </div>
        
      </div>
    </header>
  )
};

export default Header;