import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/business-services-m.jpg';
import heroImageD from '../../images/business-services-d.jpg';
import { BASE_PAGE_TILE } from '../../lib/constants';
import styles from './BusinessServicesPage.module.scss';
import BSCommercialServices from './BSCommercialServices/BSCommercialServices';


const BusinessServicesPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Business Services</title>
      </Helmet>
      <div>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc={'Man in window flipping sign that reads, "Come in. We\'re open."'}
          objectPosition='left'
          designElement='orange-radient-lines'
        >
          <h1 className='headline__h1'>
            light your<br />business<br />on <span className='color--orange'>fiber</span>
          </h1>
        </Hero>
        <section className={`grid-12 ${styles.underHero}`}>
          <p>
            Cityside Fiber small business and enterprise solutions provide reliable, 
            cost-effective connectivity that allows your company to thrive.
          </p>
        </section>
        <BSCommercialServices />
      </div>
    </>
  )
};

export default BusinessServicesPage;