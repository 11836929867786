import React from 'react';
import styles from './WFVideoSpeed.module.scss';
import imageM from '../../../images/wf-video-speed-m.jpg';
import imageD from '../../../images/wf-video-speed-d.jpg';
import poster from '../../../images/wf-video-poster.jpg';
import Video from '../../../components/Video/Video';

const WFVideoSpeed = (): JSX.Element => {
  
  return (
    <section className={`grid-12 ${styles.container}`}>
      <div className={styles.content}>

        <h2 className='headline__h2 color--dark-blue'>
          Ahh, those annoying work video calls.
        </h2>

        <div className={styles.imgWrap}>
          <picture>
            <source media="(min-width: 720px)" srcSet={imageD} />
            <img
              src={imageM}
              alt="woman on sofa smiling and looking at laptop"
              title="woman on sofa smiling and looking at laptop"
              aria-label="woman on sofa smiling and looking at laptop"
              width="100"
              height="100"
              loading="lazy"
            />
          </picture>
        </div>

        <div className={`main-text ${styles.text}`}>
          <p>
          With fiber internet, your new connection operates at symmetrical upload and download speeds. 
          Upload speed is particularly important for live-streaming video calls properly. 
          Getting your beautiful face sent quickly through the internet without freezing on your coworker&apos;s screen is important during meetings!
          </p>

          <p>
            Want to see something scary though? 
            Check what your cable company or telephone company internet shows for &quot;upload&quot; speed, 
            compared to the download speed that is advertised.
          </p>

          <Video
            src="https://www.youtube.com/embed/oUU-ZCTE0Ys?autoplay=1"
            poster={poster}
          />

          <p>
            If you&apos;re the type of person to see the word &quot;fiber&quot; and think about how your doctor is 
            always on your case about eating more of it, it&apos;s okay, you&apos;re not alone.
          </p>

          <p>
            Fiber internet sounds fancy and great, but do we really need it? And what does it actually do for us?
          </p>

          <p>
          Well, the science behind it is very cool. 
          Cityside Fiber allows whatever you are doing online to travel at lightspeed whether you are steaming movies, 
          on a video call, a professional gamer, and more! Also, the new system is made for multiple users and devices
          at the same time in the same household!
          </p>



          <h3 className='color--dark-blue'>
            A fiber optic connection means your home is able to connect to the world at speeds greater than 1 gigabit per second, 
            that&apos;s 1000 megabits per second.
          </h3>
        </div>

        <div className={styles.lower}>
          <p>
            Faster speeds of a gigabit (and beyond) means your home internet can support streaming TV, social media browsing, gaming, 
            video conferences, smart home cameras and security, all at once!
          </p>

          <p className='color--med-blue'>
            In other words, teenager-proof!
          </p>
        </div>

      </div>
    </section>
  )
};

export default WFVideoSpeed;
