import React, { useEffect, useState } from 'react';
import styles from './AsscWifi.module.scss';
import imageM from '../../../images/assc-wifi-m.jpg';
import imageD from '../../../images/assc-wifi-d.jpg';
import IconsBlocks from '../../../components/IconBlocks/IconBlocks';

const AsscWifi = (): JSX.Element => {

  return (
    <section className={`grid-12 ${styles.container}`}>
        <div className={styles.inner}>
          <div className={styles.head}>
            <h2 className='headline__h2 color--dark-blue'>
              WiFi services and creative solutions for public spaces and private networks
            </h2>
          </div>

          <div className={styles.imgWrap}>
            <picture>
              <source media="(min-width: 720px)" srcSet={imageD} />
              <img
                src={imageM}
                alt="sidewalk view of suburban block of houses"
                title="sidewalk view of suburban block of houses"
                aria-label="sidewalk view of suburban block of houses"
                width="100"
                height="100"
                loading="lazy"
              />
            </picture>
          </div>

          <div className='main-text'>
            <p>
              Cityside Fiber offers cities and neighborhoods the opportunity to extend connectivity beyond our homes. 
              We can utilize high-speed fiber and wireless systems to supply our community with public WiFi, 
              private network operations, enhance security & cameras, and improve cellular coverage.
            </p>
            <p>
              Other benefits include increased neighborhood and property values, 
              possible revenue streams a better quality of life, and overall community satisfaction
            </p>
          </div>

          <IconsBlocks
            data={[
              {
                icon: 'wifi',
                iconColor: 'med-blue',
                text: 'Public WiFi',
                className: styles.wifi
              },
              {
                icon: 'piggy-bank',
                iconColor: 'yellow',
                text: 'Increased property value'
              },
              {
                icon: 'security-camera',
                iconColor: 'orange',
                text: 'Security and safety'
              },
              {
                icon: 'network',
                iconColor: 'dark-blue',
                text: 'Dedicated network'
              },
              {
                icon: 'mobile-devices',
                iconColor: 'bright-blue',
                text: 'Managed, consistent WiFi'
              }
            ]}
          />

        </div>
    </section>
  )
};

export default AsscWifi;