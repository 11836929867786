import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/about-us-hero-m.jpg';
import heroImageD from '../../images/about-us-hero-d.jpg';
import { BASE_PAGE_TILE, ROUTES } from '../../lib/constants';
import styles from './AboutUsPage.module.scss';
import Button from '../../components/Button/Button';
import imageM from '../../images/about-switch-m.png';
import imageD from '../../images/about-switch-d.png';
import Container5050 from '../../components/Container5050/Container5050';

const AboutUsPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: About Us</title>
      </Helmet>
      <div className='bg-color--med-blue'>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc='view of neighborhood of Spahish style houses with palm trees and swimming pools'
          designElement='circle-of-dots'
          designElementColor='orange'
          sticker={<div className={styles.sticker}>Fast fiber internet<br />for Orange County</div>}
        >
          <h1 className='headline__h1'>
            Why<br />choose<br />Cityside<br />Fiber?
          </h1>
        </Hero>
        <section className={styles.getStarted}>
          <div className={styles.content}>
            <p>
              Ready for amazing internet, clear video calls, extreme gaming connections, and concierge-level customer service? 
              Cityside Fiber is a new internet provider that is local to our Orange County neighbors.
            </p>
            
            <p>
              We&apos;re familiar with your current internet challenges because we have them too. 
              So, we decided to build a new high-speed network with blazing-fast speeds, reliable connectivity, 
              better value, and a better experience.
            </p>

            <p>
              Escape your provider&apos;s temporary pricing gimmicks, long-term contracts, and lackluster service. Reach out to us today!
            </p>

            <Button
              href={ROUTES.checkAvailability.path}
              target='_self'
              size='md-to-lg'
              className='btn--md-extra-padding btn--lg-extra-padding color--dark-blue focus-contrast'
              fullWidth={true}
            >
              Get Started
            </Button>
          </div>
          
        </section>
        <Container5050 
          imageD={imageD} 
          imageM={imageM}
          imageDesc='front view of VW van with surfboards stacked on the roof'
        >
          <h2 className='headline__h2 color--dark-blue'>
            Why switch to<br />Cityside Fiber?
          </h2>
          <p>
            For decades, the cable industry has been supplying a downgraded internet experience and 
            a desire for a better customer experience.
          </p>
          <p className='color--dark-blue bold'>
            Cityside Fiber believes that we all deserve better!
          </p>
          <p>
            Better value, better service, better internet, & an overall better experience
          </p>
          <p>
            With Cityside Fiber&apos;s personal concierge services, customization options, 
            and local presence our mission is to put the customer first.
          </p>
        </Container5050>
      </div>
    </>
  )
};

export default AboutUsPage;