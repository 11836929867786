import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/why-fiber-m.jpg';
import heroImageD from '../../images/why-fiber-d.jpg';
import WFVideoSpeed from './WFVideoSpeed/WFVideoSpeed';
import WFTeleHistory from './WFTeleHistory/WFTeleHistory';
import { BASE_PAGE_TILE } from '../../lib/constants';

const WhyFiberPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Why Fiber?</title>
      </Helmet>
      <div className='bg-color--faded-neon-blue'>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc='woman sitting at office table typing on laptop'
          designElement='orange-radient-lines'
        >
          <h1 className='headline__h1'>
            Why do<br />I need<br />fiber?
          </h1>
        </Hero>
        <WFVideoSpeed />
        <WFTeleHistory />
      </div>
      
    </>
  )
};

export default WhyFiberPage;