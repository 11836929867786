import React, { useRef } from 'react';
import styles from './WHWifiPackages.module.scss'
import OfferCard from '../../../components/OfferCard/OfferCard';
import OfferCardsContainer from '../../../components/OfferCardsContainer/OfferCardsContainer';
import Button from '../../../components/Button/Button';

const WHWifiPackages = (): JSX.Element => {
  const ref1 = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const ref3 = useRef<HTMLDivElement | null>(null);

  return (
    <section className={`grid-12 ${styles.container}`}>
      <div className={styles.inner}>

        <h2 className={`headline__h2 color--dark-blue ${styles.head}`}>
          Fiber is built for the internet.
        </h2>

        <h3 className={`headline__h4 color--dark-blue ${styles.subhead}`}>
          Built for speed. Built for service. Built for the future. Built for you.
        </h3>

        <OfferCardsContainer>
          <OfferCard
            ref={ref1}
            headline='The Point'
            subhead='1 Gig Connect'
            price='$80'
            borderColor='ui-grey-4'
            reveal={true}
            onWhite={true}
          >
            <p>
              For the old school browser, smaller spaces, and fewer devices.
              An internet experience perfect for streaming with ease and priced at a fantastic value.
            </p>

            <ul>
              <li>1,000 MBPS (that&apos;s 1 Gig)</li>
              <li>1 gigabit upload & download</li>
              <li>Includes a WiFi6 router</li>
            </ul>
          </OfferCard>

          <OfferCard
            ref={ref2}
            headline='The Wedge'
            subhead='2 Gig Connect'
            price='$95'
            borderColor='ui-grey-4'
            reveal={false}
            onWhite={true}
          >
            <p>
              For the active user, a great package for larger residences running multiple devices simultaneously (computers, phones, tablets, TVs, and more).
            </p>

            <ul>
              <li>2,000 MBPS (that&rsquo;s 2 Gigs)</li>
              <li>2 gigabit upload & download</li>
              <li>Includes 1 WiFi Extender</li>
              <li>Includes a WiFi6 router</li>
            </ul>
          </OfferCard>

          <OfferCard
            ref={ref3}
            headline='The Monarch'
            subhead='5 Gig Connect'
            price='$195'
            borderColor='ui-grey-4'
            reveal={false}
            onWhite={true}
          >
            <p>
              For larger spaces, the gamer, influencer, business professional, and family.
              Your home and internet experience is connected to the world with the highest speed available.
            </p>

            <ul>
              <li>5,000 MBPS (that&rsquo;s 5 Gigs)</li>
              <li>5 gigabit upload & download</li>
              <li>Includes a WiFi6 router</li>
              <li>Includes up to 2 WiFi extender devices for WholeHome coverage – even outside!</li>
            </ul>
          </OfferCard>
        </OfferCardsContainer>

        <div className={styles.otherPackages}>
          <div>
            <p>
              <span>Looking for streaming TV packages?</span><br />
              We can help with that.
            </p>
            <Button
              size='sm'
              href='http://streaming.citysidefiber.com/start'
              attr={{rel: 'noreferrer'}}
              target='_blank'
              >
              Learn more
            </Button>
          </div>

          <div>
            <p>
              <span>Interested in a low latency,</span><br />
              10Gig+ direct fiber install?
            </p>
            <Button size='sm' href='mailto: hello@citysidefiber.com'>hello@citysidefiber.com</Button>
          </div>
        </div>
      </div>
    </section>
  )
};

export default WHWifiPackages;