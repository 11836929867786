import React from 'react';
import styles from './BSCommercialServices.module.scss';
import topImageM from '../../../images/bs-comm-services-top-m.jpg';
import topImageD from '../../../images/bs-comm-services-top-d.jpg';
import image1 from '../../../images/bs-comm-services-1.jpg';
import image2 from '../../../images/bs-comm-services-2.jpg';
import image3 from '../../../images/bs-comm-services-3.jpg';
import Button from '../../../components/Button/Button';


const BSCommercialServices = (): JSX.Element => {
  
  return (
    <section className='bg-color--faded-neon-blue'>
      <div className={`grid-12 ${styles.container}`}>
        <div className={styles.inner}>
          <div className={styles.head}>
            <h2 className='headline__h2 color--dark-blue'>
              Commercial Services
            </h2>

            <h4 className='headline__h4 color--dark-orange'>
              Small and medium businesses
            </h4>
          </div>

          <div className={`${styles.imgWrap} ${styles.topImg}`}>
            <picture>
              <source media="(min-width: 720px)" srcSet={topImageD} />
              <img
                src={topImageM}
                alt="Male clerk in shop"
                title="Male clerk in shop"
                aria-label="Male clerk in shop"
                width="100"
                height="100"
                loading="lazy"
              />
            </picture>
          </div>

          <div className='main-text'>
            <p>
              Cityside Fiber&apos;s managed Internet and WiFi solution for businesses allows you to focus on growth, 
              productivity, and profitability. We provide you with simplified IT needs and enhanced data security while 
              letting us focus on fueling your business with the power of fiber.
            </p>
            <p>
              Through the MyCity WiFi app, you choose how to prioritize multiple secure connections with just a few clicks, 
              getting you quickly back to running your business.
            </p>
          </div>

          <div className={styles.imagesWrap}>
            <div className={styles.imgWrap}>
              <img
                src={image1}
                alt="Female barista using tablet register"
                title="Female barista using tablet register"
                aria-label="Female barista using tablet register"
                width="100"
                height="100"
                loading="lazy"
              />
              <p>Dedicated Business Network</p>
            </div>

            <div className={styles.imgWrap}>
              <img
                src={image2}
                alt="hand touching tablet register"
                title="hand touching tablet register"
                aria-label="hand touching tablet register"
                width="100"
                height="100"
                loading="lazy"
              />
              <p>Secured Point of Sale Network</p>
            </div>

            <div className={styles.imgWrap}>
              <img
                src={image3}
                alt="people sitting at table in coffee shop"
                title="people sitting at table in coffee shop"
                aria-label="people sitting at table in coffee shop"
                width="100"
                height="100"
                loading="lazy"
              />
              <p>Private Customer Network</p>
            </div>
          </div>
          
          <div className={styles.btm}>
            <p>Products and pricing customized for your business needs, reach out today.</p>
            <Button
              href='mailto: sales@citysidefiber.com'
              size='md-to-lg'
              className='margin-auto bg-color--bright-blue color--dark-blue'
            >
              sales@citysidefiber.com
            </Button>
          </div>

        </div>
      </div>
    </section>
  )
};

export default BSCommercialServices;