import { VETRO_API_DOMAIN, VETRO_API_TOKEN } from "./constants";

export default abstract class VetroService {

    private static getHeaders(): {[key: string]: string} {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': VETRO_API_TOKEN || 'API_KEY',
        }
    }

    public static async getAddressMatches(address: string): Promise<any[]> {
        const resp = await fetch(`${VETRO_API_DOMAIN}/api/v2/address/match/${address}`,
        {
            method: 'GET',
            headers: this.getHeaders()
        });

        const result = await resp.json();
        return result;
    }

    public static async getLayer(): Promise<any[]> {
        const resp = await fetch(`${VETRO_API_DOMAIN}/api/v2/layers/layer/OCStructures`,
        {
            method: 'GET',
            headers: this.getHeaders()
        });

        const result = await resp.json();
        return result;
    }

    public static async getVetroAddressMatches(addressQuery: string): Promise<any[]> {
        const resp = await fetch(`${VETRO_API_DOMAIN}/v2/search`,
        {
            method: 'POST',
            headers: this.getHeaders(),
            body: addressQuery,
        });

        const result = await resp.json();
        return result;
    }

    public static async updateVetroFeature(featureQuery: string): Promise<any[]> {
        const resp = await fetch(`${VETRO_API_DOMAIN}/v2/features`,
        {
            method: 'PATCH',
            headers: this.getHeaders(),
            body: featureQuery,
        });

        const result = await resp.json();
        return result;
    }
}