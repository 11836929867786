import React from 'react';
import { Helmet } from 'react-helmet';
import LPHero from './LPHero/LPHero';
import LPPackages from './LPPackages/LPPackages';
import { BASE_PAGE_TILE, ROUTES } from '../../lib/constants';
import Container5050 from '../../components/Container5050/Container5050';
import imageM from '../../images/home-community-m.png';
import imageD from '../../images/home-community-d.png';
import Button from '../../components/Button/Button';

const LandingPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}</title>
      </Helmet>
      <div className='bg-color--dark-blue'>
        <LPHero />
        <LPPackages />
        <Container5050 
          imageD={imageD} 
          imageM={imageM} 
          imageDesc='view of California coast'
          waveMask={true}
        >
          <h2 className='headline__h2 color--dark-blue'>
            Invested in the<br />local community
          </h2>

          <p>
            At Cityside, we pride ourselves on being a part of the community as your neighbor in Orange County.
          </p>
          <p>
            We enjoy living in the same neighborhoods, the same buildings, and in the same cities that you do. 
            You will see our team in the same coffee shops, restaurants, parks, and local stores supporting our community.
          </p>
          <p>
            We want the best services. We want control of our purchases. We want real customer support. 
            We want superior high-speed fiber internet just like you!
          </p>
          <p>
            And we are bringing it to our neighbors and the community, with friendly, concierge-level service!
          </p>

          <Button 
            size='md-to-lg'
            className='bg-color--orange color--dark-blue btn--md-extra-padding btn--lg-extra-padding'
            fullWidth={true}
            href={ROUTES.checkAvailability.path}
            target='_self'
          >
            Check my availability
          </Button>
        </Container5050>
      </div>
      
    </>
  )
};

export default LandingPage;