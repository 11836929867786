import React from 'react';
import styles from './IconsBlocks.module.scss'
import { Color } from '../../lib/types';
import SVGIcon from '../SVGIcon/SVGIcon';

interface IProps {
  data: {
    icon: string;
    iconColor: Color;
    text: string;
    style?: React.CSSProperties;
    className?: string;
  }[];
}

const IconsBlocks = (props: IProps): JSX.Element => {
  return (
    <div className={styles.container}>
      {
        props.data.map((item) => (
          <div 
            key={item.icon}
            style={item.style}
            className={item.className}
          >
            <div 
              className={styles.iconWrap}
              style={item.style}
            >
              <SVGIcon id={item.icon} color={item.iconColor} />
            </div>
            <p>{item.text}</p>
          </div>
        ))
      }
    </div>
  )
};

export default IconsBlocks;