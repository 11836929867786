import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/streaming-tv-m.jpg';
import heroImageD from '../../images/streaming-tv-d.jpg';
import StreamingTVExplore from './StreamingTVExplore/StreamingTVExplore';
import { BASE_PAGE_TILE } from '../../lib/constants';
import StreamingTVCustomize from './StreamingTVCustomize/StreamingTVCustomize';

const StreamingTVPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>{BASE_PAGE_TILE}: Streaming TV</title>
      </Helmet>
      <div>
        <Hero
          imageM={heroImageM}
          imageD={heroImageD}
          imageDesc='young woman smiling holding TV remote'
          designElement='circle-of-dots'
        >
          <h1 className='headline__h1'>
            Cityside<br />makes<br />switching<br />easy
          </h1>
        </Hero>
        
        <StreamingTVCustomize />
        <StreamingTVExplore />
      </div>
      
    </>
  )
};

export default StreamingTVPage;